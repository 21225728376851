var map = require('mode-front-end/resources/assets/js/array/map');
var Modal = require('./modal');

const { initSitePromo } = require('./site-promo-modal')

module.exports = (function(window, document) {

  // ------------------------------
  // Modals
  // ------------------------------

  var modalElements = document.querySelectorAll('.js-modal-carousel__item'),
    modals = {}, i;

  // Store modals by ID
  for (i = 0; i < modalElements.length; i++) {
    if (modalElements[i].id) {
      modals['#' + modalElements[i].id] = Modal.create(modalElements[i]);
    }
  }

  // ------------------------------
  // Open Buttons
  // ------------------------------

  map(document.querySelectorAll('.js-open-modal'), function(button, index) {
    button.addEventListener('click', function(e) {
      let hash = "";
      e.preventDefault();
      if (this.tagName === "A" && this.hash) {
        hash = this.hash;
      } else if (this.tagName === "BUTTON" && this.dataset.hash) {
        hash = this.dataset.hash;
      }
      if (!hash || !modals[hash]) {
        return false;
      }

      modals[hash].setReturnFocus(this);
      modals[hash].show();
    });
  });

  initSitePromo(modals)

  // ------------------------------
  // Public
  // ------------------------------

  window.modals = modals
  return modals;

})(window, document);
