var dom = require('mode-front-end/resources/assets/js/dom');
var inArray = require('mode-front-end/resources/assets/js/array/inArray');
var map = require('mode-front-end/resources/assets/js/array/map');
var reduce = require('mode-front-end/resources/assets/js/array/reduce');
var throttle = require('mode-front-end/resources/assets/js/event/throttle');

module.exports = (function(window, document, undefined) {

  var shouldResize = false,
    elementsToMatch = document.querySelectorAll('.js-match-width__get'),
    elementsToResize = document.querySelectorAll('.js-match-width__set');

  // TODO: Group getter/setters
  // data-match-width-group="secondary-nav"

  var lastWidth = -1;

  /**
   * Reset widths
   * @return {[type]} [description]
   */
  function resetWidths() {
    map(elementsToResize, function(el) {
      el.style.width = '';
    });
  }

  /**
   * Is element visible on screen?
   * @param  {Element}  el
   * @return {Boolean}
   */
  function isVisible(el) {
    return window.getComputedStyle(el).visibility !== 'hidden' &&
      window.getComputedStyle(el).display !== 'none';
  }

  /**
   * Stretch search field to cover all the secondary header items.
   * @return {Number}
   */
  function resizeElements() {
    if (!shouldResize) {
      resetWidths();
      return -1;
    }

    // HACK: Skip the priority nav dropdown in case it's left open on resize
    var priorityNavToggle = document.querySelector('.c-priority-nav__toggle'),
      isPriorityNavActive = false, priorityNavDropdown = null;
    if (priorityNavToggle) {
      isPriorityNavActive = window.getComputedStyle(priorityNavToggle).display !== 'none';
      priorityNavDropdown = document.querySelector('.c-priority-nav__secondary-list');
    }

    // Get width of visible elements
    var width = reduce(elementsToMatch, function(total, el, index) {
      if (!isVisible(el) || (isPriorityNavActive && el === priorityNavDropdown)) {
        return total;
      }

      return total + dom.outerWidth(el);
    }, 0);

    // HACK: Adjust width to keep from covering nav separator
    width = Math.floor(width) - 3;

    // Set widths
    if (width !== lastWidth) {
      requestAnimationFrame(function() {
        map(elementsToResize, function(el) {
          el.style.width = width + 'px';
        });
      });
    }

    return width;
  }

  // TODO: Abstract these events so they can be hooked up from a controller instead of being hard-coded here
  // window.addEventListener('DOMContentLoaded', resizeElements, false);

  var resizeCallback = throttle(resizeElements, 100);

  // Watch element resize
  var resizeSensors = map(elementsToMatch, function(el) {
    return new dom.ResizeSensor(el, resizeCallback);
  });

  return {
    enable: function() {
      shouldResize = true;
      resizeElements();
    },
    disable: function() {
      shouldResize = false;
      resetWidths();
    }
  };

})(window, document);
