var dom = require('mode-front-end/resources/assets/js/dom');
var map = require('mode-front-end/resources/assets/js/array/map');
var throttle = require('mode-front-end/resources/assets/js/event/throttle');

module.exports = (function(window, document, undefined) {

  var sections = document.querySelectorAll('.js-scroll-spy__section'),
    offsets    = document.querySelectorAll('.js-scroll-spy__offset'),
    links      = document.querySelectorAll('.js-scroll-spy__link'),
    body       = document.querySelector('body');

  var windowHeight;

  // Make sure there's at least 1 section and link, and that there are the same number of sections and links
  if (sections.length < 1 || links.length < 1 || sections.length !== links.length) {
    return false;
  }

  /**
   * Activate one link and deactivate the rest.
   * @param {Number}  index
   */
  function setActiveLink(index) {
    if (links[index].parentNode.classList.contains('is-active')) {
      return false;
    }

    for (var i = links.length - 1; i >= 0; i--) {
      links[i].parentNode.classList.remove('is-active');
    }

    links[index].parentNode.classList.add('is-active');
  }

  /**
   * Open/close header add-on based on document scroll position.
   * @param  {Event}  e
   */
  function scrollHandler(e) {
    // Find the bottom-most section that is at least above the bottom quarter of the viewport
    var threshold = windowHeight * (3 / 4),
      sectionTop, i;

    for (i = sections.length - 1; i >= 0; i--) {
      sectionTop = sections[i].getBoundingClientRect().top;

      if (sectionTop < threshold) {
        setActiveLink(i);
        return true;
      }
    }
  }

  /**
   * Update document height vars.
   * @param  {Event}  e
   */
  function resizeHandler(e) {
    windowHeight = dom.window.height();
  }

  resizeHandler();

  // TODO: Create a single scroll controller to manage scroll tracking modules
  window.addEventListener('scroll', throttle(scrollHandler, 200));
  window.addEventListener('resize', throttle(resizeHandler, 200));

})(window, document);
