var dom = require('mode-front-end/resources/assets/js/dom');
var map = require('mode-front-end/resources/assets/js/array/map');
var filter = require('mode-front-end/resources/assets/js/array/filter');
var extend = require('mode-front-end/resources/assets/js/object/extend');
var animation = require('mode-front-end/resources/assets/js/animation');
var analytics = require('../analytics');
var Form = require('./form');

module.exports = (function(window, document) {

  // ------------------------------
  // Public
  // ------------------------------

  return map(document.querySelectorAll('.js-form-validate'), function(form) {
    return new Form(form);
  });

})(window, document);
