var map = require('mode-front-end/resources/assets/js/array/map');
var throttle = require('mode-front-end/resources/assets/js/event/throttle');
var tinycolor = require('tinycolor2');

module.exports = (function(window, document, undefined) {

  // ------------------------------
  // Helpers
  // ------------------------------

  // TODO: Move to mode-front-end
  function isVisible(el) {
    var rect = el.getBoundingClientRect(),
      visibileOffset = -150; // -50;

    return (
      rect.top < (window.innerHeight + visibileOffset || document.documentElement.clientHeight + visibileOffset)
    );
  }



  // ------------------------------
  // Header Video
  // ------------------------------

  /**
   * Create header videos from parent element.
   * @param {Element}  el
   */
  function HeaderVideo(el) {
    this.section = el;
    this.videos = el.querySelectorAll('.js-header-video__video');
    this.canvasses = []; // To initialize...
    this.loadedVideosCount = 0;
    this.hasScrolled = false;
    this.hasPlayed = false;

    this.init();
  }

  HeaderVideo.prototype.init = function() {
    map(this.videos, this.loadVideo.bind(this));
  };

  HeaderVideo.prototype.loadVideo = function(video, index) {
    // Get video attributes
    var src = video.getAttribute('data-src'),
      width = JSON.parse(video.getAttribute('data-width')),
      height = JSON.parse(video.getAttribute('data-height'));

    if (!src || !width || !height) {
      return false;
    }

    // Create canvas and add to canvas array at video index
    // TODO: Why does inline canvas break but JS-generated one doesn't?
    var canvas = document.createElement('canvas');
    canvas.setAttribute('width', width);
    canvas.setAttribute('height', height);
    canvas.setAttribute('class', 'c-header-video__media  /  js-header-video__canvas');
    video.parentNode.appendChild(canvas);
    // Get canvas sibling
    // var canvas = video.parentNode.querySelector('canvas');

    this.canvasses[index] = {
      el: canvas,
      context: canvas.getContext('2d'),
      width: width,
      height: height
    };

    // Load video
    video.src = src;
    video.load();
    video.addEventListener('loadeddata', this.videoLoaded.bind(this), false);
    // TODO: Video ended event
    // video.addEventListener('ended', this.videoEnded.bind(this), false);
  };

  HeaderVideo.prototype.videoLoaded = function() {
    this.loadedVideosCount++;

    if (this.hasScrolled) {
      this.playVideo();
    }
  };

  // TODO: Add fallback JPGs
  // HeaderVideo.prototype.videoEnded = function() {
  //   console.log('video ended', this);
  // };

  HeaderVideo.prototype.playVideo = function() {
    // Only play when all videos have loaded
    if (this.loadedVideosCount < this.videos.length) {
      return false;
    }

    this.startVideo();
  };

  HeaderVideo.prototype.startVideo = function() {

    if (typeof this.canvasses[0] !== "undefined"){

      // Start videos
      for (var i = 0; i < this.videos.length; i++) {
        this.videos[i].play();
        this.videos[i].classList.add('is-active');
      }

      // Check if top right pixel of first video is pure black
      var context = this.canvasses[0].context,
        width = this.canvasses[0].width,
        height = this.canvasses[0].height;
      context.drawImage(this.videos[0], 0, 0, width, height);
      var pixels = context.getImageData(0, 0, width, height);
      var hasCorrectColorSpace = ((pixels.data[width * 4 + 0] + pixels.data[width * 4 + 1] + pixels.data[width * 4 + 2]) / 3) < 1;

      // If correct, use video
      // Otherwise, adjust color space via canvas
      if (!hasCorrectColorSpace) {
        // map(this.videos, function(video) { video.classList.remove('is-active'); });
        map(this.canvasses, function(canvas) { canvas.el.classList.add('is-active'); });
        this.loopVideo();
      }

      // Flag as played
      this.hasPlayed = true;

      }
  };

  HeaderVideo.prototype.loopVideo = function() {
    // If the video isn't playing, stop loop
    // TODO: Check if either video has ended instead of just first
    if (this.videos[0].paused || this.videos[0].ended) {
      return false;
    }

    map(this.videos, this.drawVideoToCanvas.bind(this));
    requestAnimationFrame(this.loopVideo.bind(this));
  };

  HeaderVideo.prototype.drawVideoToCanvas = function(video, index) {
    var context = this.canvasses[index].context,
      width = this.canvasses[index].width,
      height = this.canvasses[index].height;

    context.drawImage(video, 0, 0, width, height);
    var pixels = context.getImageData(0, 0, width, height);
    context.putImageData(this.imageFilter(pixels), 0, 0);
  };

  HeaderVideo.prototype.imageFilter = function(pixels) {
    var d = pixels.data;

    // Recolor H.264 color space (convert 16-235 to 0-255)
    for (var i = 0; i < d.length; i += 4) {
      d[i]     = (d[i]     - 16) * (255 / (235 - 16));
      d[i + 1] = (d[i + 1] - 16) * (255 / (235 - 16));
      d[i + 2] = (d[i + 2] - 16) * (255 / (235 - 16));
    }

    return pixels;
  };



  // ------------------------------
  // Init
  // ------------------------------

  var headerVideos = map(document.querySelectorAll('.js-header-video'), function(el) {
    return new HeaderVideo(el);
  }), playedVideosCount = 0;

  var scrollHandler = throttle(function(event) {
    // If all videos have been played, remove listener
    if (playedVideosCount >= headerVideos.length) {
      window.removeEventListener('scroll', scrollHandler);
      return false;
    }

    // Play videos when sections become visible
    map(headerVideos, function(headerVideo) {
      if (headerVideo.hasScrolled) {
        return false;
      }

      if (isVisible(headerVideo.section)) {
        headerVideo.hasScrolled = true;
        headerVideo.playVideo();
      }
    });
  }, 100);

  // Only initialize if videos exist
  if (headerVideos.length > 0) {
    scrollHandler();
    window.addEventListener('scroll', scrollHandler);
  }



  // ------------------------------
  // Public
  // ------------------------------

  return headerVideos;

})(window, document);
