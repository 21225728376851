const smoothScroll = require('./smooth-scroll');

module.exports = (function(window, document, undefined) {

  function getDefaultOffset() {
    // Remove offset when headroom is disabled
    if (document.querySelector('.c-header[data-headroom="false"]')) {
      return 0;
    }
    // Otherwise, use height of sticky header
    return 54;
  }

  // TODO: Fork smoothScroll to allow offset updates, e.g., `smoothScroll.updateOffset(newOffset)`.
  // https://github.com/cferdinandi/smooth-scroll
  let options = {
    selector: '.js-smooth-scroll', // Selector for links (must be a valid CSS selector)
    // selectorHeader: '.js-smooth-scroll__header', // Selector for fixed headers (must be a valid CSS selector)
    speed: 600,
    easing: 'easeInOutCubic',
    offset: getDefaultOffset(),
    updateURL: true,
    callback: function (anchor, toggle) {}
  };

  let lastOffset = options.offset;

  function init() {
    smoothScroll.init(options);
  }

  function updateOffset(newOffset) {
    if (newOffset === lastOffset) {
      return false;
    }

    options.offset = newOffset;

    /**
     * HACK: Add public `setOffset` method to smoothScroll source to allow dynamic offset
     * https://github.com/madebymode/boars-head-website/blob/fa1585d62b0ef575afc87fe18edab45763dce253/resources/assets/js/smooth-scroll/smooth-scroll.js#L492-L506
     *
     * TODO: Better solution would be to define ranges where the offset
     * changes (e.g., offset is 0 from 0-500, then 80 from 501-1200). That way
     * a button could be clicked at the top of the page where the offset is 0
     * and correctly scroll to an element at, say, 720 where the offset ought
     * to be 80.
     */
    smoothScroll.setOffset(newOffset);
  }

  window.addEventListener('DOMContentLoaded', init);

  return {
    updateOffset: updateOffset
  };

})(window, document);
