var dom = require('mode-front-end/resources/assets/js/dom');
var map = require('mode-front-end/resources/assets/js/array/map');
var transitionEvent = require('mode-front-end/resources/assets/js/event/transitionEvent');
var analytics = require('../analytics');

module.exports = (function(window, document, undefined) {

  var isNavEnabled = false,
      options = {};

  var hasOpenNavClass  = 'has-nav-open',
      navSelector      = '.c-off-canvas-nav__nav',
      backdropSelector = '.c-off-canvas-nav__backdrop';

  var body = document.querySelector('body'),
      nav = document.querySelector(navSelector),
      backdrop = document.querySelector(backdropSelector),
      openNavButton = document.querySelector('.js-toggle-off-canvas-nav');

  // If no nav or no open button, return noop
  if (!nav || !openNavButton) {
    return {
      init: function() {},
      enable: function() {},
      disable: function() {}
    };
  }

  var trackEvent = analytics.getTrackEvent({
    'eventCategory'       : 'Main Navigation',
    // 'eventAction'         : '',
    // 'eventLabel'          : '',
    'eventNonInteraction' : false
  });

  var captureFocusHandler = function(e) {
    let activEle,feles;
    if (e.key == 'Tab') {
      activEle = nav.querySelector('.c-subnav.is-active');
      if (!activEle) { activEle = nav; }
      feles = activEle.querySelectorAll("a, button");
      if (feles) {
        const firstEle = feles[0];
        const lastEle = feles[(feles.length-1)];
        if (e.target == firstEle && e.shiftKey) {
          e.preventDefault();
          lastEle.focus();
        } else if (e.target == lastEle && !e.shiftKey) {
          e.preventDefault();
          firstEle.focus();
        }
      }
    } else if (e.key == 'Escape') {
      closeNav();
    }
  }

  openNavButton.addEventListener('click', toggleNav);

  /**
   * Toggle nav visibility
   * @param  {Event}  e
   * @return {void}
   */
  function toggleNav(e) {
    if (!!e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (body.classList.contains(hasOpenNavClass)) {
      closeNav(e);
    } else {
      openNav(e);
    }
  }

  /**
   * Open nav
   * @param  {Event}  e
   * @return {void}
   */
  function openNav(e) {
    if (!!e) {
      e.preventDefault();
      e.stopPropagation();
    }
    body.classList.add(hasOpenNavClass);
    backdrop.addEventListener('click', closeNav);
    const handler = (evt) => {
        if (evt.key == 'Escape') {
          closeNav(e);
        }
        document.removeEventListener("keydown", handler);
    };
    document.addEventListener("keydown", handler);

    nav.setAttribute("aria-hidden", "false");
    toggleButtonLabels();
    setFocus(nav);
    nav.addEventListener("keydown", captureFocusHandler);

    // GA: Track off-canvas nav clicks
    trackEvent({
      'eventAction' : 'Off-canvas Nav: Opened',
      'eventLabel'  : e.currentTarget.id
    });

    // Call onOpen callback if one is provided
    if (typeof options.onOpen === 'function') {
      var callback = function() {
        options.onOpen.call(this);
        nav.removeEventListener(transitionEvent('end'), callback);
      };
      nav.addEventListener(transitionEvent('end'), callback);
    }
  }

  /**
   * Close nav
   * @param  {Event}  e
   * @return {void}
   */
  function closeNav(e) {
    if (!!e && dom.closest(e.target, navSelector)) {
      return false;
    }

    body.classList.remove(hasOpenNavClass);
    backdrop.removeEventListener('click', closeNav);
    nav.setAttribute("aria-hidden", "true");
    toggleButtonLabels();
    setFocus(nav);
    nav.removeEventListener("keydown", captureFocusHandler);
    openNavButton.focus();

    // GA: Track off-canvas nav clicks
    trackEvent({
      'eventAction' : 'Off-canvas Nav: Closed',
      'eventLabel'  : e.currentTarget.id
    });

    // Call onClose callback if one is provided
    if (typeof options.onClose === 'function') {
      var callback = function() {
        options.onClose.call(this);
        nav.removeEventListener(transitionEvent('end'), callback);
      };
      nav.addEventListener(transitionEvent('end'), callback);
    }
  }

  function toggleButtonLabels () {
    let menuIcon = openNavButton.querySelector('.c-icon--menu');
    let menuLbl = openNavButton.querySelector('.c-icon--menu + span');
    let closeIcon = openNavButton.querySelector('.c-icon--close');
    let closeLbl = openNavButton.querySelector('.c-icon--close + span');
    let menuStr = 'inline-block' , closeStr = 'none';
    if (body.classList.contains(hasOpenNavClass)) {
      menuStr = 'none';
      closeStr = 'inline-block';
    }
    if (menuIcon) { menuIcon.style.display = menuStr; }
    if (menuLbl) { menuLbl.style.display = menuStr; }
    if (closeIcon) { closeIcon.style.display = closeStr; }
    if (closeLbl) { closeLbl.style.display = closeStr; }
  }

  /**
   * Set focus to first link when in active state. 
   *        Remove focus from all links when inactive.
   * @param {Element} nav 
   * @return {void}
   */
  function setFocus (nav) {
    let lnks = nav.querySelectorAll(".c-nav__link");
    if (lnks.length) {
      lnks.forEach((ele, i) => {
        if (nav.getAttribute("aria-hidden") == "false" && i == 0) {
          ele.focus();
          ele.setAttribute("data-focus", "focus");
        } else {
          ele.blur();
          ele.removeAttribute("data-focus");
        }
      });
    }
  }

  return {
    init: function(opts) {
      opts = opts || {};

      for (var prop in opts) {
        if (opts.hasOwnProperty(prop)) {
          options[prop] = opts[prop];
        }
      }
      toggleButtonLabels();
    },
    enable: function() {
      isNavEnabled = true;
      nav.setAttribute("aria-hidden", "true");
    },
    disable: function() {
      isNavEnabled = false;
      nav.removeAttribute("aria-hidden");
    }
  };

})(window, document);
