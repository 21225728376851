var map = require('mode-front-end/resources/assets/js/array/map');

module.exports = (function(window, document) {

  if (typeof Modernizr === 'undefined' || !Modernizr.touchevents) {
    return false;
  }

  // Remove all background video elements when touch is detected.
  // NOTE: This also affects touch-enabled desktops.
  map(document.querySelectorAll('.js-hide-modernizr-touch'), function(el) {
    el.style.display = 'none';
  });

})(window, document);
