const dom = require('mode-front-end/resources/assets/js/dom');
const map = require('mode-front-end/resources/assets/js/array/map');
const transitionEvent = require('mode-front-end/resources/assets/js/event/transitionEvent');

module.exports = (function(window, document, undefined) {

  // TODO: Resize or deactivate dropdown on headroom.js events (e.g., switching between full and essentials nav)
  const activeClass = 'is-active';

  /**
   * Create dropdown based on toggle button's hash.
   * @param  {Element}  button
   */
  function Dropdown(button) {
    this.toggleButton = button;
    this.dropdownSelector = button.hash;
    this.dropdown = document.querySelector(button.hash);

    if (!this.dropdown) {
      return null;
    }

    // Event listeners
    this.toggleClickListener = this.toggleClick.bind(this);
    this.bodyClickListener   = this.bodyClick.bind(this);

    // State
    this.isOpen = false;

    this.init();
  }

  // Add click handler to toggle button
  Dropdown.prototype.init = function() {
    this.toggleButton.addEventListener('click', this.toggleClickListener);
  };

  // Toggle open/close based on dropdown state
  Dropdown.prototype.toggleClick = function(event) {
    if (!!event) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  };

  // Close dropdown on body clicks
  Dropdown.prototype.bodyClick = function(event) {
    if (!event || !dom.closest(event.target, this.dropdownSelector)) {
      this.close();
    }
  };

  Dropdown.prototype.open = function() {
    this.toggleButton.classList.add(activeClass);
    this.toggleButton.setAttribute("aria-expanded", "true");
    this.dropdown.classList.add(activeClass);
    this.isOpen = true;
    document.body.addEventListener('click', this.bodyClickListener);
  };

  Dropdown.prototype.close = function() {
    this.toggleButton.classList.remove(activeClass);
    this.toggleButton.setAttribute("aria-expanded", "false");
    this.dropdown.classList.remove(activeClass);
    this.isOpen = false;
    document.body.removeEventListener('click', this.bodyClickListener);
  };

  function init() {
    return map(document.querySelectorAll('.js-toggle-dropdown'), (button) => new Dropdown(button));
  }

  init();
  $('body').on('reset_elements', init);



  // ------------------------------
  // Public API
  // ------------------------------

  return Dropdown;

})(window, document);
