var dom = require('mode-front-end/resources/assets/js/dom');
var throttle = require('mode-front-end/resources/assets/js/event/throttle');

module.exports = (function(window, document) {

  var main = document.querySelector('.js-footer-reveal__main'),
    footer = document.querySelector('.js-footer-reveal__footer'),
    placeholder = document.querySelector('.js-footer-reveal__placeholder'),
    windowMargin = 100; // Viewport must be at least this much taller than the footer for reveal effect

  // Confirm elements exist
  if (!main || !footer || !placeholder) {
    return false;
  }

  var windowWidth = 0,
    lastWindowWidth = 0,
    windowHeight = 0,
    lastWindowHeight = 0,
    footerHeight = 0,
    lastFooterHeight = 0;

  /**
   * Fix/unfix footer based on window size.
   * @return {void}
   */
  function resizeHandler() {
    // Is window the same size?
    windowWidth  = dom.window.width();
    windowHeight = dom.window.height();

    if (windowWidth === lastWindowWidth && windowHeight === lastWindowHeight) {
      return false;
    } else {
      lastWindowWidth = windowWidth;
      lastWindowHeight = windowHeight;
    }

    // Is footer taller than viewport?
    //footerHeight = dom.outerHeight(footer);
    const footerRect = footer.getBoundingClientRect()
    footerHeight = footerRect.height

    if (footerHeight <= (windowHeight - windowMargin)) {
      fixFooter();
    } else {
      unfixFooter();
    }
  }

  /**
   * Match footer height in placeholder.
   * @return {void}
   */
  function fixFooter() {
    if (!footer.classList.contains('is-fixed')) {
      footer.classList.add('is-fixed');
    }

    // Has footer height changed?
    if (footerHeight === lastFooterHeight) {
      return false;
    } else {
      lastFooterHeight = footerHeight;
    }

    requestAnimationFrame(function() {
      placeholder.style.height = (footerHeight) + 'px';
    });
  }

  /**
   * Unfix footer.
   * @return {void}
   */
  function unfixFooter() {
    if (!footer.classList.contains('is-fixed')) {
      return false;
    }

    footer.classList.remove('is-fixed');
    footerHeight = 0;
    lastFooterHeight = 0;

    requestAnimationFrame(function() {
      placeholder.style.height = '0px';
    });
  }

  function scrollBodyOnFooterFocus (e) {
    if (document.activeElement.closest("#footer")) {
      document.documentElement.scroll({
        top: document.documentElement.offsetHeight,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  window.addEventListener('DOMContentLoaded', function() {
    setTimeout(function() {
      resizeHandler()
    }, 500)
  });
  window.addEventListener('resize', throttle(resizeHandler, 100));
  window.addEventListener('keyup', scrollBodyOnFooterFocus);

})(window, document);
