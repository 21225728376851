const dom = require('mode-front-end/resources/assets/js/dom');
const map = require('mode-front-end/resources/assets/js/array/map');
const inArray = require('mode-front-end/resources/assets/js/array/inArray');
const throttle = require('mode-front-end/resources/assets/js/event/throttle');
const menuAim = require('./menu-aim');
const analytics = require('../analytics');

module.exports = (function(window, document, undefined) {

  let openButtonSelector    = '.js-open-subnav',
    disableSubnavSelector   = '.js-disable-subnav',
    menuAimSelector         = '.js-menu-aim',
    menuAimRowSelector      = '.js-menu-aim__toggle',
    subnavSelector          = '.js-menu-aim__menu',
    subnavContainerSelector = '.js-menu-aim__container',
    activeClass             = 'is-active';

  let options       = {},
    subnavActive    = [],
    isSubnavEnabled = true;

  let trackEvent = analytics.getTrackEvent({
    'eventCategory'       : 'Main Navigation',
    // 'eventAction'         : '',
    // 'eventLabel'          : '',
    'eventNonInteraction' : false
  });

  /**
   * Close all subnavs on body clicks.
   * @param  {Event}  e
   * @return {void}
   */
  function closeSubnavs(e) {
    let openButtons = document.querySelectorAll(openButtonSelector);
    let subnavs = document.querySelectorAll(subnavSelector);
    let i;

    for (i = 0; i < openButtons.length; i++) {
      openButtons[i].classList.remove(activeClass);
      openButtons[i].setAttribute("aria-expanded", "false");
    }

    for (i = 0; i < subnavs.length; i++) {
      subnavs[i].classList.remove(activeClass);
      setSubnavFocus(subnavs[i]);
    }
  }

  /**
   * Get subnav for button.
   * @param  {Element}  button
   * @return {Element}
   */
  function getSubnavForButton(button) {
    return button.parentNode.querySelector(subnavSelector);
  }

  /**
   * Set subnav active state.
   * @param  {Element}  subnav
   * @param  {Boolean}  isActive
   * @return {void}
   */
  function setSubnavActive(subnav, isActive) {
    let index = subnav.getAttribute('data-subnav-id');

    // Defer active state changes so that links open subnavs on first tap and
    // link to landing pages on second tap. Using 500ms because 700ms feels slightly too long.
    // - 500ms is a common double-click time: http://ux.stackexchange.com/a/40367
    // - FastClick sets the max touch delay to 700ms: https://github.com/ftlabs/fastclick/blob/3db9f899c25b7b2e1517dc5cc17494ec9094bc43/lib/fastclick.js#L98-L103
    let tapDelay = 500;

    setTimeout(function() {
      subnavActive[index] = isActive;
    }, tapDelay);
  }

  function keydownCloseNav(e) {
    if (e.key != 'Escape') {
      return;
    }
    // User hit escape
    closeSubnavs()
    document.removeEventListener('keydown', keydownCloseNav)
  }

  /**
   * Open subnav
   * @param  {Element}  button
   * @return {void}
   */
  function activateSubnav(button) {
    // Avoid double-click from multiple events on mobile
    if (!isSubnavEnabled) {
      return false;
    }

    // HACK: Disable subnavs inside of priority nav dropdown
    if (dom.closest(button, disableSubnavSelector) && document.body.classList.contains('has-priority-nav') ) {
      return false;
    }

    let subnav = getSubnavForButton(button);

    if (!subnav) {
      return false;
    }

    button.classList.add(activeClass);
    button.setAttribute("aria-expanded", "true");
    subnav.classList.add(activeClass);
    setSubnavActive(subnav, true);
    setSubnavFocus(subnav);

    document.addEventListener('keydown', keydownCloseNav)

    // Call onOpen callback if one is provided
    if (typeof options.onOpen === 'function') {
      options.onOpen.call(this);
    }
  }

  /**
   * Set focus to first link when in active state. 
   *        Remove focus from all links when inactive.
   * @param {Element} subnav 
   * @return {void}
   */
  function setSubnavFocus (subnav) {
    let lnks = subnav.querySelectorAll(".c-subnav__link");
    if (lnks.length) {
        lnks.forEach((ele, i) => {
        if (subnav.classList.contains(activeClass) && i == 0) {
          ele.focus();
          ele.setAttribute("data-focus", "focus");
        } else {
          ele.blur();
          ele.removeAttribute("data-focus");
        }
      });
    }
  }

  /**
   * Close subnav
   * @param  {Element}  button
   * @return {void}
   */
  function deactivateSubnav(button) {
    if (!isSubnavEnabled) {
      return false;
    }

    let subnav = getSubnavForButton(button);

    if (!subnav) {
      return false;
    }

    button.classList.remove(activeClass);
    button.setAttribute("aria-expanded", "false");
    subnav.classList.remove(activeClass);
    setSubnavActive(subnav, false);
  }

  /**
   * Init menu aim.
   * @return {void}
   */
  function initMenuAim() {
    menuAim.create(document.querySelectorAll(menuAimSelector), {
      activate: activateSubnav,
      deactivate: deactivateSubnav,
      exitMenu: function() {
        if (!isSubnavEnabled) {
          return false;
        }

        closeSubnavs();

        // Returning true here forces menuAim to reset the active row on exit
        return true;
      },
      // Selector for identifying which elements in the menu are rows
      // that can trigger the above events. Defaults to "> li".
      rowSelector: menuAimRowSelector,
      // You may have some menu rows that aren't submenus and therefore
      // shouldn't ever need to 'activate.' If so, filter submenu rows w/
      // this selector. Defaults to '*' (all elements).
      submenuSelector: '*',
      // 'right', 'left', 'above', or 'below'
      submenuDirection: 'below'
    });

    // Force extra click to avoid linking to landing pages before subnavs open on touch devices
    map(document.querySelectorAll(menuAimRowSelector), function(button, index) {
      let subnav = getSubnavForButton(button);

      if (!subnav) {
        return false;
      }

      subnav.setAttribute('data-subnav-id', index);

      button.addEventListener('click', function(e) {
        if (!isSubnavEnabled) {
          return false;
        }

        // HACK: Disable subnavs inside of priority nav dropdown
        if (dom.closest(button, disableSubnavSelector) && document.body.classList.contains('has-priority-nav')) {
          return false;
        }

        // HACK: Let keyboards follow landing page links instead of dropdowns
        // TODO: Refactor menuAim to allow keyboard access
        if (e.x === 0 && e.y === 0) { // isKeyboard
          return false;
        }

        if (!subnavActive[index]) {
          e.preventDefault();
        } else {
          // GA: Track landing page clicks
          trackEvent({
            'eventAction' : 'Clicked ' + this.textContent.trim(),
            'eventLabel'  : this.textContent.trim()
          });
        }
      });
    });
  }



  // ------------------------------
  // Public API
  // ------------------------------

  return {
    init: function(opts) {
      opts = opts || {};

      for (let prop in opts) {
        if (opts.hasOwnProperty(prop)) {
          options[prop] = opts[prop];
        }
      }

      initMenuAim();
    },
    closeSubnavs: function() {
      closeSubnavs();
    },
    enable: function() {
      isSubnavEnabled = true;
    },
    disable: function() {
      isSubnavEnabled = false;
      closeSubnavs();
    }
  };

})(window, document);
