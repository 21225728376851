import ShareService from './share-service'

let existingShareInstances = []

export default function initShareServices() {

  document.addEventListener('DOMContentLoaded', () => {
    (existingShareInstances || []).forEach((shareInstance) => {
      shareInstance.destroy()
    })

    const shareServices = [...document.querySelectorAll('.share-service')]

    existingShareInstances = shareServices.map((shareServiceElem) => {
      const share = new ShareService(shareServiceElem)
      share.init()
      return share;
    })
  })
}
