var dom = require('mode-front-end/resources/assets/js/dom');
var map = require('mode-front-end/resources/assets/js/array/map');
var filter = require('mode-front-end/resources/assets/js/array/filter');
var transitionEvent = require('mode-front-end/resources/assets/js/event/transitionEvent');
var animation = require('mode-front-end/resources/assets/js/animation');
var analytics = require('../analytics');
var elementClosest = require('element-closest');

// Google Analytics
var trackFormEvent = analytics.getTrackEvent({
  'eventCategory': 'Form Submissions',
  'eventNonInteraction': false
});

// Custom `select`s Pt. 1
// TODO: Move to mode-front-end and return as module instead of adding to global scope
require('./selectFx');

module.exports = (function(window, document) {

  /**
   * Create a Friends and Family form.
   * @param {Element}  el
   */
  function Form(el, options) {
    this.options = Object.assign({
      disableSubmitHandler: false,
    }, options)

    // TODO: Validate arguments
    this.form = el;
    this.action = el.action;
    this.submitButton = el.querySelector('button[type="submit"]');

    // Custom `select`s Pt. 2
    map(this.form.querySelectorAll('.c-select'), function(el) { new SelectFx(el); });

    map(this.form.elements, (field) => {
      field.addEventListener('focus', this.focusHandler)
    })

    var selectMessageSelector = el.getAttribute('data-success-message-selector');
    if (selectMessageSelector !== null) {
      this.successMessage = document.querySelector(selectMessageSelector);
    } else {
      this.successMessage = el.parentNode.querySelector('.js-form__success-message');
    }

    if (this.successMessage) {
      this.successMessage.style.display = 'none';
    }

    var submittedContainerSelector = el.getAttribute('data-submitted-container-selector');
    if (submittedContainerSelector) {
      this.submittedContainer = document.querySelector(submittedContainerSelector);
    } else {
      this.submittedContainer = this.form;
    }

    this.trackingData = JSON.parse(el.getAttribute('data-ga-form-submit') || '{}');

    // Optional error handlers that can be set externally
    this.successHandler = null;
    this.errorHandler = null;

    // Unset trackingData if none exists
    if (Object.getOwnPropertyNames(this.trackingData).length < 1) {
      this.trackingData = null;
    }

    // Get names of all form inputs
    this.validFields = filter(this.form.elements, (field) => field.name).map((field) => field.name);

    // Init
    if (!this.options.disableSubmitHandler) {
      this.form.addEventListener('submit', this.submitHandler.bind(this));
    }

    // Backup the initial error message to each elements dataset
    Array.from(this.form.querySelectorAll('.c-form__error')).forEach((errorElement) => {
      errorElement.dataset.defaultMessage = errorElement.innerHTML
    })

    // Identify Required Fields
    this.markRequired();

    // Attach the form handler to the DOM
    el.formHandler = this;
  }

  /**
   * Append *required field identifier to input placeholder text and
   * visually hidden field label
   *
   */
  Form.prototype.markRequired = function() {
    Array.from(this.form.elements).forEach((ele, i) => {
      let str, req = ele.hasAttribute("required");
      if (req) {
        // if (["INPUT", "TEXTAREA"].indexOf(ele.tagName) != -1) {
        //   if (ele.hasAttribute("placeholder")) {
        //     str = ele.getAttribute("placeholder") + " (*required)";
        //   } else {
        //     str = "(*required)";
        //   }
        //   ele.setAttribute("placeholder", str);
        // }
        let span = ele.previousElementSibling;
        if (span && (span.classList.contains("u-visually-hidden") || span.classList.contains("c-form__label-text"))) {
          span.innerHTML = `${span.innerHTML} (*required)`;
        }
      }
    });
  }

  /**
   * Toggle form field valid classes.
   * @param  {Element}  el
   * @param  {Boolean}  isValid
   * @return {Boolean}
   */
  Form.prototype.toggleValidClass = function(el, isValid, message) {

    let errorElement = el.parentNode.querySelector('.c-form__error')

    if (isValid) {
      el.classList.remove('is-invalid');
    } else {
      el.classList.add('is-invalid');
    }

    if (el.classList.contains('c-radio__input')) {
      let radioGroup = el.closest('.c-radio__group')

      if (radioGroup) {
        if (isValid) {
          radioGroup.classList.remove('is-invalid')
        } else {
          radioGroup.classList.add('is-invalid')
        }
      }
    }

    // HACK: Handle custom select elements
    if (el.classList.contains('c-select')) {
      errorElement = el.parentNode.parentNode.querySelector('.c-form__error')
      if (isValid) {
        el.parentNode.classList.remove('is-invalid');
      } else {
        el.parentNode.classList.add('is-invalid');
      }
    }

    if (errorElement) {
      errorElement.innerHTML = errorElement.dataset.defaultMessage;
      if (typeof message !== 'undefined') {
        errorElement.innerHTML = message;
      }
    }

    // HACK: Target form field element instead of input
    // var parents = dom.parents(el, '.c-form__field');
    // var formField = (parents.length > 0) ? parents[0] : null;
    // if (formField && isValid) {
    //   parents[0].classList.remove('is-invalid');
    // } else if (formField) {
    //   parents[0].classList.add('is-invalid');
    // }

    return true;
  };

  Form.prototype.focusHandler = function(e) {
    let fieldContainer = e.target.closest('.c-form__field')
    if (fieldContainer) {
      fieldContainer.classList.add('has-interaction')
    }
  }

  /**
   * Form submissions.
   * @param  {Event}  e
   * @return {Boolean}
   */
  Form.prototype.submitHandler = function(e) {
    e.preventDefault();
    this.submit()
  };

  Form.prototype.submit = function() {

    // Prevent multiple clicks sending extra requests
    if (this.isWaitingForResponse) {
      return false;
    }

    var fields = this.form.elements,
      formData = {};

    var emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var zipRegEx = /^\d{5}([\-]?\d{4})?$/;

    // Get values of valid field
    for (var i = 0; i < fields.length; i++) {
      if (this.validFields.indexOf(fields[i].name) < 0) {
        continue;
      }

      //trim whitespace
      fields[i].value = fields[i].value.trim();

      // Save data
      if (fields[i].type === 'checkbox' || fields[i].type === 'radio') {
        if (fields[i].checked) {
          formData[fields[i].name] = fields[i].value;
        }
      } else {
        formData[fields[i].name] = fields[i].value;
      }

      // Validate
      if (fields[i].name === 'email' && !emailRegExp.test(fields[i].value)) {
        this.toggleValidClass(fields[i], false);
      } else if (fields[i].name === 'phone' && (fields[i].value + '').replace(/[^\d]+/, '') < 10) {
        this.toggleValidClass(fields[i], false);
      } else if (fields[i].name === 'zipcode' && !zipRegEx.test(fields[i].value)) {
        this.toggleValidClass(fields[i], false);
      } else if (fields[i].type === 'checkbox' && !fields[i].checked && fields[i].hasAttribute('required')) {
        this.toggleValidClass(fields[i], false);
      } else if (fields[i].type === 'radio') {
        // If this is a radio and required, search for all radios with this name
        // so that we can check if any of them are checked
        if (fields[i].hasAttribute('required')) {
          let foundChecked = this.form.querySelector(`input[name=${fields[i].name}]:checked`)
          this.toggleValidClass(fields[i], foundChecked ? true : false);
        }
      } else if (fields[i].type.indexOf('select') > -1) {

        let selectedIndex = fields[i].selectedIndex || -1;

        if (fields[i].hasAttribute('required') &&
          (typeof fields[i].options[selectedIndex] === 'undefined' ||
          fields[i].options[selectedIndex].value.length === 0)) {
          this.toggleValidClass(fields[i], false);
          continue;
        }

        this.toggleValidClass(fields[i], true);
      } else if (!fields[i].value || fields[i].value.length === 0) {
        this.toggleValidClass(fields[i], false);
      } else {
        this.toggleValidClass(fields[i], true);
      }
    }

    formData.url = document.URL;
    formData.title = document.title;

    var invalids = document.querySelectorAll('.is-invalid');
    if (invalids.length) {
      invalids[0].focus();
      if (this.trackingData) {
        this.track({ 'eventLabel': 'Invalid Fields', 'eventValue': 0 });
        this.track({ 'eventAction': this.trackingData.eventAction + ' - Invalid Fields', 'eventLabel': Array.from(invalids).map(el => el.name).filter(name => !!name).join(', '), 'eventValue': 0 });
      }
      return false;
    }

    // Disable submit button until response comes back
    this.isWaitingForResponse = true;
    this.submitButton.setAttribute('disabled', true);

    var existingButtonHtml = this.submitButton.innerHTML
    if (this.submitButton.hasAttribute('data-waiting-text')) {
      this.submitButton.innerHTML = this.submitButton.getAttribute('data-waiting-text')
    }

    if (this.options.contentType == 'application/json') {
      formData = JSON.stringify(formData)
    }

    const requestOptions = Object.assign({
      url: this.action,
      data: formData,
      method: 'POST',
    }, this.options)

    // TODO: Replace with jQuery-less POST
    $.ajax(requestOptions)
      .then(this.responseHandler.bind(this))
      .then(() => {
        this.submitButton.innerHTML = existingButtonHtml
      });
    // DEBUG
    // this.responseHandler({ results: true });

    return true;
  }

  /**
   * Handle requests response
   * @param  {Object}  data
   * @return {void}
   */
  Form.prototype.responseHandler = function(data) {
    this.isWaitingForResponse = false;
    this.submitButton.removeAttribute('disabled');

    if (data.results) {
      this.track({ 'eventLabel': 'Successful Submission', 'eventValue': 1 });
      return this.showSuccess(data);
    }

    this.track({ 'eventLabel': 'Failed Submission', 'eventValue': 0 });
    return this.showErrors(data);
  };

  /**
   * Show success message
   * @return {Boolean}
   */
  Form.prototype.showSuccess = function(data) {
    animation.slideUp(this.submittedContainer);
    this.setSuccessfulSubmitState();
    // this.form.classList.add('is-submitted');

    if (this.successMessage) {
      this.successMessage.style.display = 'block';
      animation.slideDown(this.successMessage);
      this.successMessage.classList.add('is-active');
      this.form.parentNode.parentNode.classList.add('is-active');
      this.successMessage.focus();
    }
    if (typeof this.successHandler === 'function') {
      this.successHandler.call(this, data);
    }
    return true;
  };

  /**
   * Set submitted form css class.
   * ADA Specific: Disable form and inputs from receiving tab focus
   *      since the form has now disappeared from view.
   *      Maybe removing the form completely from the DOM would be a simplier
   *      more thorough approach? [MK]
   */
  Form.prototype.setSuccessfulSubmitState = function() {
    this.form.classList.add('is-submitted');
    this.form.style.display = "none";
    this.form.setAttribute("disabled", "disabled");
    this.form.setAttribute("aria-hidden", "true");
    map(this.form.elements, function(ele) {
      ele.setAttribute("disabled", "disabled");
      ele.form.setAttribute("aria-hidden", "true");
    });
  };

  /**
   * Show error messages
   * @return {Boolean}
   */
  Form.prototype.showErrors = function(data) {
    if (data.errors) {
      var errorFields = Object.keys(data.errors);
      var toggleValidClass = this.toggleValidClass;

      map(this.form.elements, function(field) {
        toggleValidClass(field, errorFields.indexOf(field.name) === -1, data.errors[field.name]);
      });
    }

    this.form.classList.add('has-error');

    if (typeof this.errorHandler === 'function') {
      this.errorHandler.call(this, data);
    }

    return true;
  };

  /**
   * Track Google Analytics events for form actions.
   * @param  {Object}  params
   * @return {Boolean}
   */
  Form.prototype.track = function(params) {
    if (!this.trackingData) {
      return false;
    }

    trackFormEvent(Object.assign({}, this.trackingData, params));

    return true;
  };

  return Form;

})(window, document);
