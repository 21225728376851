var dom = require('mode-front-end/resources/assets/js/dom');
var map = require('mode-front-end/resources/assets/js/array/map');
var throttle = require('mode-front-end/resources/assets/js/event/throttle');
var analytics = require('../analytics');

module.exports = (function(window, document, undefined) {

  var isNavEnabled  = false,
      nav           = document.querySelector('.c-multi-level-nav'),
      levelSelector = '.c-multi-level-nav__level',
      listSelector  = '.c-nav__list',
      prevLevel;

  // If no nav, return noop
  if (!nav) {
    return {
      backToLevel: function() {},
      enable: function() {},
      disable: function() {}
    };
  }

  var allLevels      = nav.querySelectorAll(levelSelector),
    firstLevel       = allLevels[0],
    lists            = nav.querySelectorAll(listSelector),
    nextLevelButtons = nav.querySelectorAll('.js-open-subnav'),
    breadcrumbs      = nav.querySelector('.c-nav-breadcrumbs');

  var currentLevel,
    nextLevel;

  var trackEvent = analytics.getTrackEvent({
    'eventCategory'       : 'Main Navigation',
    // 'eventAction'         : '',
    // 'eventLabel'          : '',
    'eventNonInteraction' : false
  });

  // HACK: Get landing page buttons
  var landingPageButtons = map(nextLevelButtons, function(button, index) {
    return dom.closest(button, '.js-menu-aim__toggle');
  });

  /**
   * Landing page click handler
   * @param  {Event}  e
   * @return {void}
   */
  function landingPageClickHandler(e) {
    if (!isNavEnabled) {
      return;
    }

    // GA: Track landing page clicks
    trackEvent({
      'eventAction' : 'Clicked ' + this.textContent.trim(),
      'eventLabel'  : this.textContent.trim()
    });
  }

  /**
   * Next level click handler
   * @param  {Event}  e
   * @return {void}
   */
  function nextLevelClickHandler(e) {
    if (!isNavEnabled) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();

    currentLevel = dom.closest(this, levelSelector) || firstLevel;
    nextLevel    = dom.parents(this, '.c-nav__item')[0].querySelector('.c-multi-level-nav__level');

    // Height Pt. 1
    setLevelHeight(currentLevel, nextLevel.offsetHeight);
    currentLevel.classList.add('is-next-level');
    nextLevel.classList.add('is-active');

    setFocus(nextLevel);

    // GA: Track multi-level nav clicks
    trackEvent({
      'eventAction' : 'Multi-level Nav: Next Level',
      'eventLabel'  : nextLevel.querySelector('.c-nav__link > span').textContent.trim()
    });
  }

  /**
   * Next level click handler
   * @param  {Event}  e
   * @return {void}
   */
  function setFocus(level) {
    level = level || false;
    let lnks = [];

    allLevels.forEach((lvl, i) => {  
      lnks = lvl.querySelectorAll(".c-multi-level-nav__button--back, .c-nav__link, .c-subnav__link");
      lnks.forEach((lnk, i) => { lnk.blur(); });
    });

    if (level !== false) {
      lnks = level.querySelectorAll(".c-multi-level-nav__button--back");
      if (lnks.length == 0) {
        lnks = level.querySelectorAll(".c-nav__link, .c-subnav__link");
      }
      if (lnks.length > 0) {
        // Focus on the first link if the multi-level nav is in use
        lnks[0].focus();
      }
    } else {
      lnks = nav.querySelectorAll(".c-nav__link--primary");
      if (prevLevel) {
        let prevLevelSubButton = prevLevel.parentNode.querySelector('.js-open-subnav')
        if (prevLevelSubButton) {
          prevLevelSubButton.focus()
        }
      }
    }

    prevLevel = level
  }

  /**
   * Update level height
   * @param  {Element}  el
   * @param  {Number}   height
   * @return {void}
   */
  function setLevelHeight(el, height) {
    el.setAttribute('style', 'height: ' + height + 'px;');
  }

  /**
   * Reset level height
   * @param  {Element} el
   * @param  {Number}  height
   * @return {void}
   */
  function resetLevelHeight(el) {
    el.setAttribute('style', 'height: auto;');
  }

  /**
   * Reset nav to a given level
   * @param  {Number}  levelDepth
   * @return {void}
   */
  function backToLevel(levelDepth) {
    var selector = Array(levelDepth + 1).join(levelSelector + ' ').trim(),
      nextSelector = Array(levelDepth + 2).join(levelSelector + ' ').trim(),
      levels = [], nextLevels = [], i = 0;

    // Height Pt. 2
    map(lists, resetLevelHeight);

    // If we're going back to the first level, reset everything
    if (!selector) {
      levels = nextLevels = firstLevel.querySelectorAll(levelSelector);
      firstLevel.classList.remove('is-next-level');
    // Otherwise, reset the lower levels
    } else {
      levels = firstLevel.querySelectorAll(selector);
      nextLevels = firstLevel.querySelectorAll(nextSelector);
    }

    // Animate
    for (i = 0; i < levels.length; i++) {
      levels[i].classList.remove('is-next-level');
    }

    // Hide
    setTimeout(function() {
      for (i = 0; i < nextLevels.length; i++) {
        nextLevels[i].classList.remove('is-active');
      }

      // TODO: jQuery-free nav scroll?
      // $(nav).scrollTop(0);
    }, 300);
  }

  /**
   * Reset levels click handler.
   * @param  {Event}  e
   * @return {void}
   */
  function resetLevels(e) {
    if (!!e) {
      e.preventDefault();
    }
    setFocus();
    backToLevel(0);
    
  }

  // Landing page buttons
  map(landingPageButtons, function(button, index) {
    if (!button) {
      return false;
    }

    button.addEventListener('click', landingPageClickHandler);
  });

  // Next level buttons
  map(nextLevelButtons, function(button, index) {
    button.addEventListener('click', nextLevelClickHandler);
  });

  // Previous level buttons
  // TODO: If we add additional levels, this will need to work for the in-between levels too
  map(document.querySelectorAll('.js-back-a-level'), function(button) {
    button.addEventListener('click', function(e) {
      e.preventDefault();
      e.stopPropagation();

      // GA: Track multi-level nav clicks
      trackEvent({
        'eventAction' : 'Multi-level Nav: Previous Level',
        'eventLabel'  : nextLevel.querySelector('.c-nav__link > span').textContent.trim()
      });

      resetLevels();
    });
  });

  // Height Pt. 3
  window.addEventListener('resize', throttle(function() {
    if (!isNavEnabled || !nextLevel) {
      return;
    }

    if (currentLevel !== firstLevel) {
      setLevelHeight(currentLevel, nextLevel.offsetHeight);
    }
  }, 100));

  return {
    backToLevel: backToLevel,
    resetLevels: resetLevels,
    enable: function() {
      isNavEnabled = true;
    },
    disable: function() {
      isNavEnabled = false;
      resetLevels();
    }
  };

})(window, document);
