var dom = require('mode-front-end/resources/assets/js/dom');
var search = require('./search');
var offCanvasNav = require('./off-canvas-nav');
var multiLevelNav = require('./multi-level-nav');
var priorityNav = require('./priority-nav');
var subnav = require('./subnav');
var matchWidth = require('./match-width');
var scrollSpy = require('./scroll-spy');
// TODO: Make HeaderAddOn a separate module from stickyNav
var stickyNav = require('./sticky-nav');

module.exports = (function(window, document) {

  /**
   * Is the off canvas nav active?
   * @return {Boolean}
   */
  function isOffCanvasNav() {
    let mobileNavBtn = document.querySelector('.js-toggle-off-canvas-nav');

    if (!mobileNavBtn) {
      return false;
    }

    return window.getComputedStyle(mobileNavBtn).getPropertyValue('display') !== 'none';
  }

  /**
   * Switch between desktop and mobile navs.
   * @return {void}
   */
  function resizeHandler() {
    // Mobile Navigation
    if (isOffCanvasNav()) {
      offCanvasNav.enable();
      multiLevelNav.enable();
      priorityNav.disable();
      subnav.disable();
      matchWidth.disable();
    // Desktop Navigation
    } else {
      offCanvasNav.disable();
      multiLevelNav.disable();
      priorityNav.enable();
      subnav.enable();
      matchWidth.enable();
    }
  }

  /**
   * Derive and set the :active primary nav element
   *      by parsing the url and matching the href attribute
   *
   * @return {void}
   */
  function setActiveHandler (e) {
    const toks = window.location.pathname.split("/");
    let url = "";
    while (toks.length > 0) {
      url = toks.join("/");
      let ele = document.querySelectorAll(
        `#primary-nav .c-nav__list--primary > li > .c-nav__link--primary[href^="${url}"], #primary-nav .c-nav__list--secondary > li > .c-nav__link--secondary[href^="${url}"]`
      );

      // Removed this for now. It's kind of awkward to focus on the first nav element after showing
      // the sub menus on focus.
      if (ele.length > 0) {
        ele[0].setAttribute("aria-current", "true");
        break;
      }

      toks.pop();
    }



    let skipToMain = document.querySelector('#skip-to-main-content')
    if (skipToMain) {
      skipToMain.focus()
    }
  }

  window.addEventListener('resize', resizeHandler);
  window.addEventListener('DOMContentLoaded', resizeHandler);
  window.addEventListener('DOMContentLoaded', setActiveHandler);

  /**
   * Reset header elements.
   * @return {void}
   */
  function init() {
    subnav.init({
      onOpen: function() {
        search.close();
        priorityNav.close();
      }
    });

    // TODO: Make sure offCanvasNav works after `reset_elements` event
    offCanvasNav.init({
      onOpen: function() {
        //subnav.closeSubnavs();
        search.close();
        priorityNav.close();
      },
      onClose: function() {
        multiLevelNav.resetLevels();
      }
    });

    // TODO: Make sure search works after `reset_elements` event
    search.init({
      onOpen: function() {
        subnav.closeSubnavs();
        priorityNav.close();
      }
    });

    stickyNav.init();

    // Close all when focus leaves the Header
    document.body.addEventListener('keyup', (e) => {
      if (e.key != "Tab") { return; }
      if (!document.activeElement.closest("#header")) {
        subnav.closeSubnavs();
        search.close();
        priorityNav.close();
      }
    });
  }

  init();

  // Reset after page transitions
  // TODO: Make sure priorityNav works after `reset_elements` event
  // TODO: Make sure multiLevelNav works after `reset_elements` event
  // TODO: Make sure matchWidth works after `reset_elements` event
  // TODO: Make sure scrollSpy works after `reset_elements` event
  $('body').on('reset_elements', init);

  // ------------------------------
  // Public API
  // ------------------------------

  return {
    isOffCanvasNav: isOffCanvasNav,
    el: () => stickyNav.headroom().elem,
    addOn: () => stickyNav.addOn().el,
  };

})(window, document);
