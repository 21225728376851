//javascript error tracing
require('./sentry');

require('./libs/custom-event');

// Shims + Polyfills
require('mode-front-end/resources/assets/js/shim/requestAnimationFrame');
require('svg4everybody')();
require('core-js/fn/array/from');
require('core-js/fn/object/assign');

// Browser Detects
const ie = require('mode-front-end/resources/assets/js/browserDetect/internetExplorer');
const ios = require('mode-front-end/resources/assets/js/browserDetect/ios');
const fontFeatures = require('mode-front-end/resources/assets/js/browserDetect/fontFeatures');

// Responsive images + Lazy loading
document.createElement('picture');
require('picturefill');
// `object-fit` polyfill
// https://github.com/aFarkas/lazysizes/tree/gh-pages/plugins/object-fit
require('lazysizes/plugins/object-fit/ls.object-fit');
require('lazysizes/plugins/parent-fit/ls.parent-fit');
require('lazysizes/plugins/respimg/ls.respimg');
require('lazysizes');

import store from 'store2'

import initShareServices from './share-services';
initShareServices()


// Layout
const header = require('./header');
const smoothScroll = require('./smooth-scroll');
const throttle = require('mode-front-end/resources/assets/js/event/throttle');

function updateSmoothScrollOffset() {
  // Get lowest bounding rect (since addOn height may actually be taller than visible area)
  // Use timeout to avoid getting incorrect positions
  setTimeout(function() {
    var height = header.el().getBoundingClientRect().height;
    var offset = header.addOn().getBoundingClientRect().height;
    smoothScroll.updateOffset(height + offset);
  }, 100);
}

window.updateSmoothScrollOffset = updateSmoothScrollOffset;

if (header.addOn()) {
  window.addEventListener('DOMContentLoaded', updateSmoothScrollOffset);
  window.addEventListener('headerAddOnChanged', updateSmoothScrollOffset, false);
  window.addEventListener('resize', throttle(updateSmoothScrollOffset, 100));
}

window.balanceText = require('mode-front-end/resources/assets/js/typography/balanceText');
window.balanceText.elements.push('.js-balance-text');
window.addEventListener('typekitFontsLoaded', balanceText.apply);

if (typeof APP_CONFIG === 'undefined') {
  var APP_CONFIG = {};
}

require('./analytics').init({ addDefaultClickHandlers: APP_CONFIG.overrideAnalytics ? false : true });
require('./animations');
// require('./accordion');
require('./dropdown');
require('./footer');
require('./forms');
require('./header-video');
require('./hide-modernizr-touch');
require('./modal');
require('./prevent-default');
require('./search');
require('./show-more');
require('./social');
require('./tabs');
require('./toggle');
require('./video-lazyload');
require('./video-player').init();

require('./foodservice/login');



const PDFObject = require('pdfobject')

// position:sticky polyfill
// https://github.com/wilddeer/stickyfill
require('Stickyfill');
$('.js-sticky').Stickyfill();
// Reset after page transitions
$('body').on('reset_elements', function() {
  window.Stickyfill.kill();
  $('.js-sticky').Stickyfill();
});

// Fix transitioning between normal pages and stuff that's controlled by smoothstate
window.addEventListener('popstate', function(e) {
  if (e.state && e.state.id) {
    location.reload();
  }
});

const noticeBanner = document.querySelector('.notice-banner')
if (noticeBanner) {

  if (!store.session('hideQualityNotice')) {
    noticeBanner.classList.remove('u-display-none')
    document.body.classList.add('has-notice')
  }

  const noticeBannerClose = noticeBanner.querySelector('.close-btn')
  if (noticeBannerClose) {
    noticeBannerClose.addEventListener('click', function(e) {
      e.preventDefault()
      document.body.classList.remove('has-notice')
      noticeBanner.classList.add('u-display-none')
      store.session({ hideQualityNotice: true });
    })
  }
}

Array.from(document.querySelectorAll('.pdf-embed')).forEach(function(pdfEmbed) {
  PDFObject.embed(pdfEmbed.dataset.src, pdfEmbed);
})
