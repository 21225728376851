// TODO: Why does this make `gulp --production` choke?
// const on = require('mode-front-end/resources/assets/js/event/on');

// TODO: Move to mode-front-end
module.exports = (function(window, document, undefined) {

  // on('body', 'click', '.js-prevent-default', (event) => {
  //   event.preventDefault();
  // });

  $('body').on('click', '.js-prevent-default', (event) => {
    event.preventDefault();
  });

})(window, document);
