var map = require('mode-front-end/resources/assets/js/array/map');
// var transitionEvent = require('mode-front-end/resources/assets/js/event/transitionEvent');

module.exports = (function(window, document, undefined) {

  var toggleButtons = document.querySelectorAll('.js-toggle-sibling');

  // TODO: Move to mode-front-end
  function nextElement(elem) {
    do {
      elem = elem.nextSibling;
    } while (elem.nodeType !== 1);

    return elem || null;
  }

  map(toggleButtons, function(button, index) {
    button.addEventListener('click', function(e) {
      e.preventDefault();
      this.classList.toggle('is-active');
      nextElement(this).classList.toggle('is-active');
    });
  });

  return toggleButtons;

})(window, document);
