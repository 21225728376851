var map = require('mode-front-end/resources/assets/js/array/map');

module.exports = (function(window, document, undefined) {
  'use strict';

  // Cut the mustard
  // http://webfieldmanual.com/guides/cutting-the-mustard.html
  if (!window.requestAnimationFrame || !document.documentElement.classList) {
    return false;
  }

  document.documentElement.classList.add('js-fade-enabled');

  /**
   * Check whether an element is visible within the viewport.
   * @param  {Element}  el
   * @return {Boolean}
   */
  // TODO: Move to mode-front-end
  function isVisible(el) {
    var rect = el.getBoundingClientRect(),
      visibileOffset = 0; // -50;

    return (
      rect.top < (window.innerHeight + visibileOffset || document.documentElement.clientHeight + visibileOffset)
    );
  }

  /**
   * Fade in elements as they scroll into view.
   * @param {Element[]}  elements
   */
  function FadeIn(elements) {
    this.remainingElements = [];

    // Init fade classes + flatten elements array
    for (var i = 0; i < elements.length; i++) {
      var el = elements[i];
      var children = el.children;

      for (var j = 0; j < children.length; j++) {
        children[j].classList.add('js-fade__child');
        children[j].classList.add('js-fade__child--' + (j + 1));
        this.remainingElements.push(children[j]);
      }
    }

    // Show visible elements
    this.addClassToVisibleElements('already-visible', this.remainingElements);

    // Begin loop to check for new visibile elements
    requestAnimationFrame(this.checkVisibility.bind(this));
  }

  /**
   * Fade in visible elements.
   * @return {void}
   */
  FadeIn.prototype.checkVisibility = function() {
    this.remainingElements = this.addClassToVisibleElements('come-in', this.remainingElements);

    if (this.remainingElements.length > 0) {
      requestAnimationFrame(this.checkVisibility.bind(this));
    }
  };

  /**
   * Add class to visible elements. Return remaining elements.
   * @param  {String}  className
   * @return {Element[]}
   */
  FadeIn.prototype.addClassToVisibleElements = function(className, elements) {
    var remainingElements = [];

    for (var i = 0; i < elements.length; i++) {
      if (isVisible(elements[i])) {
        elements[i].classList.add(className);
      } else {
        remainingElements.push(elements[i]);
      }
    }

    return remainingElements;
  };

  // TODO: Remove animation classes on complete, and remove listeners once everything's in
  window.addEventListener('DOMContentLoaded', function() {
    new FadeIn(document.querySelectorAll('.js-fade'));
  });

  return FadeIn;

})(window, document);
