var map = require('mode-front-end/resources/assets/js/array/map');
require('core-js/fn/object/assign');
require('./gascrolldepth.custom');

var clickEventElements = null;
var pageViewEventElements = null;

module.exports = (function(window, document, undefined) {

  // ------------------------------
  // Helpers
  // ------------------------------

  /**
   * HACK: Check whether the sticky nav is active.
   * @return {Boolean}
   */
  var body = document.querySelector('body');
  function hasStickyNav() {
    return body.classList.contains('has-sticky-nav');
  }



  // ------------------------------
  // TrackEvent
  // ------------------------------
  /**
   * Helper for tracking Google Analytics events through Google Tag Manager.
   * @param  {Object}    params
   * @return {Function}
   */
  function TrackEvent(params) {

    // Combine new params with event defaults
    this.event = Object.assign({
      'event'               : 'GAEvent',
      'eventCategory'       : null,
      'eventAction'         : null,
      'eventLabel'          : null,
      'eventValue'          : 0,
      'eventNonInteraction' : false
    }, params);
  }

  /**
   * Helper for tracking Google Analytics events through Google Tag Manager.
   * @param  {Object}  params
   * @return {void}
   */
  TrackEvent.prototype.fire = function(params) {
    var event = Object.assign({}, this.event, params);

    // Note: Requiring all event fields will break Virtual Pageviews
    // if (!event.eventCategory ||
    //     !event.eventAction) {
    //   return false;
    // }

    // HACK: Set eventValue to 0 or 1 depending on whether the nav is in sticky mode
    if (event.eventCategory === 'Main Navigation' && hasStickyNav()) {
      event.eventValue = 1;
    }

    // Only trigger event if GTM has loaded
    if (typeof dataLayer !== 'undefined' && typeof dataLayer.push === 'function') {
      dataLayer.push(event);
      console.log('event', event)
    }

    // DEBUG
    // console.log(event);
  };

  /**
   * Helper to scope TrackEvent params and return `fire` method
   * @param  {Object}    params
   * @return {Function}
   */
  function getTrackEvent(params) {
    var trackEvent = new TrackEvent(params);

    return trackEvent.fire.bind(trackEvent);
  }



  // ------------------------------
  // Click
  // ------------------------------

  var gaTrackEvent = getTrackEvent({
    'eventNonInteraction': false
  });



  /**
   * Track click event for specified elements. Use the element's text as the
   * default eventLabel. Allow custom overrides.
   * @param  {Event}  e
   * @return {void}
   */
  function gaClickEvent(e) {
    console.log(this.getAttribute('data-ga-click-event') || '{}')
    var data = JSON.parse(this.getAttribute('data-ga-click-event') || '{}');

    if (!data.eventCategory) {
      return false;
    }

    gaTrackEvent({
      'eventCategory' : data.eventCategory,
      'eventAction'   : data.eventAction || 'Clicked',
      'eventLabel'    : data.eventLabel  || this.textContent.trim(),
      'eventValue'    : data.eventValue  || 0
    });

    // DEBUG
    // e.preventDefault();
  }

  /**
   * Track click event for specified elements. Use the element's text as the
   * default eventLabel. Allow custom overrides.
   * @param  {Event}  e
   * @return {void}
   */
  function gaVirtualPageview(e) {
    var data = this.getAttribute('data-ga-virtual-pageview');

    if (!data) {
      return false;
    }

    gaTrackEvent({
      'event': 'GAVirtualPageview',
      'eventCategory': data
    });

    // DEBUG
    // e.preventDefault();
  }

  /**
   * Setup default event handlers.
   * @return {void}
   */
  function init({ addDefaultClickHandlers = true } = {}) {

    resetClickHandlers({ addDefaultClickHandlers });

    // Scroll %
    gascrolldepth.init({
      minHeight: 0,
      elements: [],
      percentage: true,
      percentageMarks: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      userTiming: false,
      pixelDepth: false,
      nonInteraction: true
      // DEBUG
      // eventHandler: function(data) {
      //   console.log(data);
      // }
    });
  }

  function addScrollDepthElements(elements) {
    gascrolldepth.addElements(elements)
  }

  function clearScrollDepthElements() {
    gascrolldepth.clearElements()
  }

  function setScrollDepthElements(elements) {
    gascrolldepth.clearElements()
    gascrolldepth.addElements(elements)
  }

  function resetClickHandlers({ addDefaultClickHandlers = true } = {}) {
    if (addDefaultClickHandlers) {

      // Remove existing click handler events
      if (clickEventElements) {
        map(clickEventElements, function(el, index) {
          el.removeEventListener('click', gaClickEvent);
        });
      }

      // Remove existing click handler events
      if (pageViewEventElements) {
        map(clickEventElements, function(el, index) {
          el.removeEventListener('click', gaVirtualPageview);
        });
      }

      clickEventElements = document.querySelectorAll('[data-ga-click-event]')
      pageViewEventElements = document.querySelectorAll('[data-ga-virtual-pageview]')

      // Click events
      map(clickEventElements, function(el, index) {
        el.addEventListener('click', gaClickEvent);
      });

      // Virtual pageviews
      map(pageViewEventElements, function(el, index) {
        el.addEventListener('click', gaVirtualPageview);
      });
    }
  }

  // ------------------------------
  // Public API
  // ------------------------------

  return {
    init: init,
    addScrollDepthElements: addScrollDepthElements,
    clearScrollDepthElements: clearScrollDepthElements,
    setScrollDepthElements: setScrollDepthElements,
    resetClickHandlers: resetClickHandlers,
    getTrackEvent: getTrackEvent
  };

})(window, document);
