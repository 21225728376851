require('../libs/socialite.js');

module.exports = (function(window, document) {

  // ------------------------------
  // Social Share Buttons
  // ------------------------------

  // Pinterest
  // Add global `initPinterestButtons` to reinitialize buttons after page transitions
  // https://stackoverflow.com/a/20951200/1786459
  let script = document.createElement('script');
  script.async = true;
  script.src = document.location.protocol + '//assets.pinterest.com/js/pinit.js';
  script.setAttribute('data-pin-build', 'initPinterestButtons');
  document.getElementsByTagName('head')[0].appendChild(script);


  // Everything else
  Socialite.load();



  // ------------------------------
  // Print Buttons
  // ------------------------------

  $('body').on('click', '.js-print', function(e) {
    e.preventDefault();
    window.print();
  });

})(window, document, window.document, window.Socialite);
