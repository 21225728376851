// TODO: Move to mode-front-end
var queryParser = require('../libs/query-parser');
var debounce = require('../libs/debounce');

var map = require('mode-front-end/resources/assets/js/array/map');
var analytics = require('../analytics');

module.exports = (function(window, document) {

  var globalSearch = {
    container: document.getElementById('global-search'),
  };

  // If no search, return noop
  if (!globalSearch.container) {
    return {
    };
  }

  globalSearch.form = globalSearch.container.querySelector('form');
  globalSearch.input = globalSearch.container.querySelector('.js-search-term');
  globalSearch.dropDown = globalSearch.container.querySelector('.js-search-autocomplete');

  // If no form, input, or dropdown return noop
  if (!globalSearch.form || !globalSearch.input || !globalSearch.dropDown) {
    return {
    };
  }

  // Track global terms
  var terms;

  var trackEvent = analytics.getTrackEvent({
    'eventCategory'       : 'Main Navigation',
    // 'eventAction'         : '',
    // 'eventLabel'          : '',
    'eventNonInteraction' : false
  });

  /**
   * Get search results
   * @param  {Object}  searchParams
   * @param  {Number}  page
   * @param  {String}  template
   * @return {Object}
   */
  function getSearchResults(searchParams, page, template) {
    var queryStr = 'terms=' + (searchParams.terms || '').trim();

    if (typeof searchParams.type !== 'undefined'){
      queryStr += '&type=' + (searchParams.type || '').trim();
    }

    //match the landing page limit that dynamically sets the offset - this prevents duplicates on page=2
    queryStr += '&limit=10';
    queryStr += '&highlight=1';

    if (typeof page !== 'undefined') {
      queryStr += '&page=' + page;
    }

    if (typeof template !== 'undefined') {
      queryStr += '&template=' + template;
    }

    if (window.locale === 'es'){
      return $.get('/es/search/results?' + queryStr)    }

    return $.get('/search/results?' + queryStr)
  }

  /**
   * Autocomplete click handler
   * @param  {Event}  e
   * @return {void}
   */
  function autocompleteClickHandler(e) {
    // GA: Track search clicks
    trackEvent({
      'eventAction' : 'Clicked Search Autocomplete For "' + terms + '"',
      'eventLabel'  : this.textContent.trim()
    });
  }

  /**
   * Add tracking events to autocomplete results
   * @return {void}
   */
  function addTrackingToAutocompleteResults() {
    map(globalSearch.container.querySelectorAll('.c-autocomplete__item'), (button, index) => {
      // Remove duplicate listeners (just in case)
      button.removeEventListener('click', autocompleteClickHandler);
      button.addEventListener('click', autocompleteClickHandler);
    });
  }

  function handleKeyboardNavigation(e) {

    // Ignore instances when there's no results
    if (!globalSearch.results || globalSearch.results.length == 0) {
      return;
    }

    let index;
    let currentResults = globalSearch.results.filter((result, i) => {
      if (result.getAttribute('aria-current') == 'true') {
        index = i
        return true
      }

      return false
    })

    let currentResult = currentResults.length && currentResults[0] || null

    if (e.keyCode == 40) {

      if (!currentResult) {
        let nextResult = globalSearch.results[0]

        console.log('next result', nextResult)

        nextResult.focus()
        nextResult.setAttribute('aria-current', 'true')
      } else {
        currentResult.setAttribute('aria-current', 'false')

        console.log('index', index)

        let nextIndex = index + 1
        if (nextIndex >= globalSearch.results.length) {
          nextIndex = 0
        }

        let nextResult = globalSearch.results[nextIndex]
        nextResult.focus()
        nextResult.setAttribute('aria-current', 'true')
      }

      return
    }

    if (e.keyCode == 38) {
      if (!currentResult) {
        let prevResult = globalSearch.results[0]

        prevResult.focus()
        prevResult.setAttribute('aria-current', 'true')
      } else {
        currentResult.setAttribute('aria-current', 'false')

        console.log('index', index)

        let prevIndex = index - 1
        if (prevIndex < 0) {
          prevIndex = globalSearch.results.length - 1
        }

        let prevResult = globalSearch.results[prevIndex]
        prevResult.focus()
        prevResult.setAttribute('aria-current', 'true')
      }
    }
  }

  // Autocomplete Dropdown
  globalSearch.input.addEventListener('input', debounce((e) => {
    // Update global terms
    terms = e.target.value;

    getSearchResults({ terms: terms, limit: 5 }, undefined, 'search-results-autocomplete').then((data) => {

      if (!data.hasResults) {
        globalSearch.dropDown.classList.add('is-empty');
        document.removeEventListener('keyup', handleKeyboardNavigation)
        return;
      }

      console.log(globalSearch.dropDown)

      globalSearch.dropDown.innerHTML = data.results;
      globalSearch.dropDown.classList.remove('is-empty');

      document.addEventListener('keyup', handleKeyboardNavigation)

      globalSearch.results = Array.from(globalSearch.dropDown.querySelectorAll('.o-list--search__result'))

      addTrackingToAutocompleteResults();
    });
  }, 300));



  // Search Submissions
  globalSearch.form.addEventListener('submit', function(e) {
    // Update global terms
    terms = globalSearch.input.value;

    // GA: Track search submissions
    trackEvent({
      'eventAction' : 'Submitted Search For "' + terms + '"',
      'eventLabel'  : terms
    });
  });



  // Search Page
  var search = document.getElementById('search');
  if (search) {

    var searchParams = queryParser(location.search);
    var searchList = search.querySelector('.js-search-list');

    var page = 1;
    if (searchParams.hasOwnProperty('page')) {
      page = searchParams.hasOwnProperty('page');
    }

    var loadMoreBtn = search.querySelector('.js-load-more');

    if (!loadMoreBtn) {
      return;
    }

    loadMoreBtn.addEventListener('click', (e) => {

      e.preventDefault();

      page += 1;

      getSearchResults(searchParams, page).then((data) => {
        // hide the load more button if the last page has been loaded
        if (!data.hasMorePages) {
          loadMoreBtn.classList.add('u-display-none');
        }

        searchList.insertAdjacentHTML('beforeend', data.results);
      });
    });
  }

})(window, document);
