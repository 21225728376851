module.exports = (function(window, document) {

  // Show More
  var container = document.querySelector('.js-show-more__container');

  if (container) {
    var items = container.querySelectorAll('.js-show-more__item'),
        showMoreBtn = document.querySelector('.js-show-more__button'),
        counter = 5,
        counterIncrement = 11;

    function loadMore(toShow) {
      $(items).removeClass('is-hidden');

      var hiddenElems = $('.o-list__item:gt(' + counter + ')');
      $(hiddenElems).addClass('is-hidden');

      // Show/Hide Load More Button
      if (hiddenElems.length == 0) {
        $(showMoreBtn).addClass('is-hidden');
      } else {
        $(showMoreBtn).removeClass('is-hidden');
      };

      counter = counterIncrement + counter + 1;
    }

    loadMore(counterIncrement);

    showMoreBtn.addEventListener('click', function(e) {
      e.preventDefault();
      loadMore(counterIncrement);
    });
  }

})(window, document);
