var dom = require('mode-front-end/resources/assets/js/dom');
var map = require('mode-front-end/resources/assets/js/array/map');
var transitionEvent = require('mode-front-end/resources/assets/js/event/transitionEvent');

module.exports = (function(window, document, undefined) {

  // TODO: Resize or deactivate search field on headroom.js events (e.g., switching between full and essentials nav)
  var searchContainerSelector = '#global-search',
      toggleButtonSelector    = '.js-toggle-search',
      activeClass             = 'is-active',
      bodyActiveClass         = 'has-nav-search-open';

  var body            = document.querySelector('body'),
      searchContainer = document.querySelector(searchContainerSelector);

  var options = {};

  // If no nav, return noop
  if (!searchContainer) {
    return {
      init: function() {},
      open: function() {},
      close: function() {}
    };
  }

  var searchInput     = searchContainer.querySelector('input'),
      toggleButtons   = document.querySelectorAll(toggleButtonSelector);

  // TODO: Make this a generic `activateParent` pattern, or something
  map(toggleButtons, function(button, index) {
    button.addEventListener('click', toggleSearch);
  });

  /**
   * Toggle search form visibilty.
   * @param  {Event}  e
   * @return {void}
   */
  function toggleSearch(e) {
    if (!!e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (body.classList.contains(bodyActiveClass)) {
      closeSearch();
    } else {
      openSearch();
    }
  }

  /**
   * Open search form and auto-select input
   * @param  {Event}  e
   * @return {void}
   */
  function openSearch(e) {
    if (!!e) {
      e.preventDefault();
      e.stopPropagation();
    }
    searchContainer.classList.add(activeClass);
    searchContainer.addEventListener(transitionEvent('end'), selectSearchInput);
    document.addEventListener("keydown", escapeHandler);
    body.classList.add(bodyActiveClass);
    searchContainer.setAttribute("aria-expanded", "true");
    // Call onOpen callback if one is provided
    if (typeof options.onOpen === 'function') {
      options.onOpen.call(this);
    }
  }

  /**
   * Automatically select the search input. Added to separate function to
   * avoid conflicts with CSS transition events.
   * @return {void}
   */
  function selectSearchInput() {
    searchInput.focus();
    searchInput.setSelectionRange(0, 9999); // Focus on iOS
    searchContainer.removeEventListener(transitionEvent('end'), selectSearchInput);
  }

  /**
   * Close search form on body clicks
   * @param  {Event}  e
   * @return {void}
   */
  function closeSearch(e) {
    // Don't close on clicks inside of search field
    if (!!e && dom.closest(e.target, searchContainerSelector)) {
      return false;
    }
    document.removeEventListener("keydown", escapeHandler);
    searchContainer.classList.remove(activeClass);
    body.classList.remove(bodyActiveClass);
    searchContainer.setAttribute("aria-expanded", "false");
  }
  // Add close event to body clicks
  document.querySelector('body').addEventListener('click', closeSearch);

  /**
   * Close search form on escape keydown
   * @param  {Event}  evt
   * @return {void}
   */
  function escapeHandler (evt) {
    if (evt.key == 'Escape') {
      closeSearch();
    }
  };
  
  return {
    init: function(opts) {
      opts = opts || {};

      for (var prop in opts) {
        if (opts.hasOwnProperty(prop)) {
          options[prop] = opts[prop];
        }
      }
    },
    open: openSearch,
    close: closeSearch
  };

})(window, document);
