module.exports = function(query) {

  var params = {};

  if (query.length === 0) {
    return params;
  }

  query.replace(/^\?/, '').split('&').forEach((entry) => {

    var key, val;
    key = entry.split('=', 2)[0];
    val = entry.split('=', 2)[1];

    if (typeof val === 'undefined') {
      val = null;
    }

    if (val !== null) {
      val = decodeURIComponent(val);
    }

    params[key] = val;
  });

  return params;
};
