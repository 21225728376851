var dom = require('mode-front-end/resources/assets/js/dom');
var map = require('mode-front-end/resources/assets/js/array/map');
var analytics = require('../analytics');

module.exports = (function(window, document, undefined) {

  // Save the original state of the header
  var body          = document.querySelector('body'),
      nav           = document.querySelector('.c-priority-nav'),
      primaryList   = document.querySelector('.c-priority-nav__primary-list'),
      secondaryList = document.querySelector('.c-priority-nav__secondary-list'),
      toggleContainer = document.querySelector('.c-priority-nav__toggle'),
      toggleButton  = document.querySelector('.c-priority-nav__toggle a'), // HACK: Select anchor inside of toggle as separate element for active states
      itemsToMove   = document.querySelectorAll('.c-priority-nav__not-priority'),
      itemIndices   = [];

  var activeClass     = 'is-active',
      bodyActiveClass = 'has-priority-nav',
      isNavEnabled    = false,
      isNavOpen       = false;

  // If no nav, return noop
  if (!nav) {
    return {
      close: function() {},
      open: function() {},
      enable: function() {},
      disable: function() {}
    };
  }

  var trackEvent = analytics.getTrackEvent({
    'eventCategory'       : 'Main Navigation',
    // 'eventAction'         : '',
    // 'eventLabel'          : '',
    'eventNonInteraction' : false
  });

  // Get item indeces on load
  for (var i = 0; i < itemsToMove.length; i++) {
    itemIndices[i] = dom.getIndex(itemsToMove[i]);
  }

  // TODO: Make this a generic `activateParent` pattern, or something
  toggleButton.addEventListener('click', toggleDropdown);

  /**
   * Is priority nav active?
   * @return {Boolean}
   */
  function isActive() {
    return nav.classList.contains(activeClass);
  }

  /**
   * Reset nav
   * @return {void}
   */
  function reset() {
    if (!isActive()) {
      return false;
    }

    map(itemsToMove, moveBack);
    nav.classList.remove(activeClass);
    toggleContainer.style.display = 'none';
    body.classList.remove(bodyActiveClass);
  }

  /**
   * Update nav
   * @return {void}
   */
  function updateNav() {
    // TODO: Dynamic check whether nav items fit in the header
    var navItemsFit = dom.window.width() >= 1300;

    if (!isNavEnabled || navItemsFit) {
      reset();
    } else if (!isActive()) {
      map(itemsToMove, moveToSecondaryList);
      nav.classList.add(activeClass);
      toggleContainer.style.display = '';
      body.classList.add(bodyActiveClass);
    }
  }

  /**
   * Move items to the secondaryList
   * @param  {Element}  item
   * @return {void}
   */
  function moveToSecondaryList(item, index) {
    // TODO: Prepend to the list in order
    // secondaryList.appendChild(item);
    secondaryList.insertBefore(item, secondaryList.children[index]);

    var link = item.querySelector('a');

    item.classList.add('c-nav__item--secondary');
    link.classList.remove('c-nav__link--primary');
    link.classList.add('c-nav__link--secondary');
  }

  /**
   * Move items to the secondaryList
   * @param  {Element}  item
   * @return {void}
   */
  function moveBack(item, index) {
    primaryList.insertBefore(item, primaryList.children[itemIndices[index]]);

    var link = item.querySelector('a');

    item.classList.remove('c-nav__item--secondary');
    link.classList.add('c-nav__link--primary');
    link.classList.remove('c-nav__link--secondary');
  }

  /**
   * Toggle dropdown visibilty
   * @param  {Event}  e
   * @return {void}
   */
  function toggleDropdown(e) {
    if (!!e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (secondaryList.classList.contains(activeClass)) {
      closeDropdown();
    } else {
      openDropdown();
    }
  }

  /**
   * Open dropdown
   * @param  {Event}  e
   * @return {void}
   */
  function openDropdown(e) {
    if (!!e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (isNavOpen) {
      return false;
    }

    isNavOpen = true;

    toggleButton.classList.add(activeClass);
    secondaryList.classList.add(activeClass);

    // GA: Track priority nav clicks
    trackEvent({
      'eventAction' : 'Priority Nav: Opened',
      'eventLabel'  : 'Open'
    });

    // Add close event to body clicks
    body.addEventListener('click', closeDropdown);
  }

  /**
   * Close dropdown on body clicks
   * @param  {Event}  e
   * @return {void}
   */
  function closeDropdown(e) {
    // Don't close on clicks inside of dropdown
    if (!!e && dom.closest(e.target, '.c-priority-nav__secondary-list')) {
      return false;
    }

    if (!isNavOpen) {
      return false;
    }

    isNavOpen = false;

    toggleButton.classList.remove(activeClass);
    secondaryList.classList.remove(activeClass);

    // GA: Track priority nav clicks
    trackEvent({
      'eventAction' : 'Priority Nav: Closed',
      'eventLabel'  : 'Close'
    });

    // Remove close event from body clicks
    body.removeEventListener('click', closeDropdown);
  }

  /**
   * HACK: Add tracking for subnav links
   */
  function subnavLinkClickHandler(e) {
    trackEvent({
      'eventAction' : 'Clicked ' + this.textContent.trim(),
      'eventLabel'  : this.textContent.trim()
    });
  }
  function addTrackingForSubnavLinks() {
    map(itemsToMove, function(item, index) {
      // HACK: Only track clicks for subnav elements without `data-ga-click-event`
      var link = item.querySelector('.js-menu-aim__toggle');
      if (link && !link.getAttribute('data-ga-click-event')) {
        link.addEventListener('click', subnavLinkClickHandler);
      }
    });
  }
  function removeTrackingForSubnavLinks() {
    map(itemsToMove, function(item, index) {
      // HACK: Only track clicks for subnav elements without `data-ga-click-event`
      var link = item.querySelector('.js-menu-aim__toggle');
      if (link && !link.getAttribute('data-ga-click-event')) {
        link.removeEventListener('click', subnavLinkClickHandler);
      }
    });
  }



  return {
    close: closeDropdown,
    open: openDropdown,
    enable: function() {
      isNavEnabled = true;
      updateNav();
      addTrackingForSubnavLinks();
    },
    disable: function() {
      isNavEnabled = false;
      updateNav();
      removeTrackingForSubnavLinks();
    }
  };

})(window, document);
