'use strict';

// TODO: Move to mode-front-end
module.exports = (function(window, document) {

  require('../libs/custom-event');
  var clearStack = require('mode-front-end/resources/assets/js/event/clearStack');
  var map = require('mode-front-end/resources/assets/js/array/map');

  var clickHandler = function(e) {
    /**
     * TODO: Determine whether we actually need to stopPropagation here.
     * If so, we'll need a workaround for handling `document` event listeners
     * within modals (e.g., custom `select`s used in modal forms).
     * For example, see the modal form on the Foodservice page.
     */
    // e.stopPropagation();
  };

  var backdropClickHandler = function(e) {
    if (e.target != e.currentTarget) {
      return;
    }
    this.hide();
  };

  var resizeHandler = function(e) {
    if (this.contentElement) {
      var self = this;
      var clientRect = self.contentElement.getBoundingClientRect();
      if (window.innerHeight < clientRect.height + 200) {
        self.element.classList.add('c-modal--align-top');
      } else if (window.innerHeight > clientRect.height + 200) {
        self.element.classList.remove('c-modal--align-top');
      }
    }
  };

  var escapeHandler = function(e) {
    if (e.key == 'Escape') {
      this.hide();
    }
  };

  var dismissHandler = function(e) {
    e.preventDefault();
    this.hide();
  };

  var captureFocusHandler = function(e) {
    let feles;
    if (e.key == 'Tab') {
      feles = this.element.querySelectorAll("a, input, select, button");
      if (feles) {
        const firstEle = feles[0];
        const lastEle = feles[(feles.length-1)];
        if (e.target == firstEle && e.shiftKey) {
          e.preventDefault();
          lastEle.focus();
        } else if (e.target == lastEle && !e.shiftKey) {
          e.preventDefault();
          firstEle.focus();
        }
      }
    }
  }

  function Modal() {}

  Modal.prototype.element = null;
  Modal.prototype.contentElement = null;
  Modal.prototype.returnFocusElement = null;
  Modal.prototype.clickHandler = null;
  Modal.prototype.backdropClickHandler = null;
  Modal.prototype.resizeHandler = null;
  Modal.prototype.escapeHandler = null;
  Modal.prototype.dismissHandler = null;
  Modal.prototype.captureFocusHandler = null;
  Modal.prototype.onHide = null;

  Modal.prototype.show = function() {
    var self = this;
    document.body.classList.add('modal-open');
    this.element.classList.add('is-active');
    this.setFocus();
    this.element.addEventListener("keydown", self.captureFocusHandler);
    this.element.dispatchEvent(new CustomEvent('modal.show'));
    
    // bind event handlers once the stack is cleared.
    // prevents triggering these event handlers from bubbling click events
    // when it's initially opened.
    clearStack(function() {

      self.element.scrollTop = 0;

      // ensure the modal is in the correct display state for the viewport
      self.resizeHandler();

      // handle when the backdrop is clicked
      if (!self.element.hasAttribute('disable-backdrop-dismiss')) {
        self.element.addEventListener('click', self.backdropClickHandler);
      }

      // handle when the modal is clicked. should prevent closing the modal
      self.element.addEventListener('click', self.clickHandler);

      window.addEventListener('resize', self.resizeHandler);
      if (!self.element.hasAttribute('disable-escape-dismiss')) {
        document.addEventListener('keydown', self.escapeHandler);
      }

      map(self.element.querySelectorAll('.js-modal-dismiss'), function(dismissTrigger) {
        dismissTrigger.addEventListener('click', self.dismissHandler);
      });
    });
  };

  Modal.prototype.hide = function() {
    if (this.onHide !== null && this.onHide.constructor.name === "Function") {
      this.onHide();
    }
    if (document.body.classList.contains('modal-open')) {
      this.element.dispatchEvent(new CustomEvent('modal.hide'));
    }
    document.body.classList.remove('modal-open');
    this.element.classList.remove('is-active');
    this.setFocus();
    this.element.removeEventListener("keydown", this.captureFocusHandler);
    this.element.removeEventListener('click', this.clickHandler);
    this.element.removeEventListener('click', this.backdropClickHandler);
    window.removeEventListener('resize', this.resizeHandler);
    document.removeEventListener('keydown', this.escapeHandler);
  };

  Modal.prototype.isActive = function() {
    return this.element.classList.contains('is-active');
  }

  Modal.prototype.setReturnFocus = function (ele) {
    if (typeof ele == "string") {
      ele = document.getElementById(ele);
    }
    if (typeof ele.focus == "function") {
      this.returnFocusElement = ele;
    }
  }

  Modal.prototype.setFocus = function () {
    let focusEle, style, inpts;
    if (this.element.classList.contains('is-active')) {
      inpts = this.contentElement.querySelectorAll("input[type=text],input[type=email]");
      if (inpts) {
        for (let i = 0; i < inpts.length; i++) {
          style = window.getComputedStyle(inpts[i]);
          if (style.display != 'none' && style.visibility != 'hidden') {
            focusEle = inpts[i];
            break;
          }
        }
      }
      if (!focusEle) {
        focusEle = this.contentElement.querySelector(".c-modal__close, .js-modal-dismiss");
      }
      if (focusEle) {
        focusEle.focus();
        focusEle.setAttribute("data-focus","focus");
      }
    } else {
      focusEle = this.contentElement.querySelector("[data-focus=focus]");
      if (focusEle) {
        focusEle.blur();
        focusEle.removeAttribute("data-focus");
        if (this.returnFocusElement) {
          this.returnFocusElement.focus();
        }
      }
    } 
  }


  var modal = {
    create: function(selector) {
      var modal = Object.create(Modal.prototype);
      modal.element = selector && selector.nodeType === 1 ? selector : document.querySelector(selector);
      modal.contentElement = modal.element.querySelector('.c-modal__content');
      modal.clickHandler = clickHandler.bind(modal);
      modal.backdropClickHandler = backdropClickHandler.bind(modal);
      modal.resizeHandler = resizeHandler.bind(modal);
      modal.escapeHandler = escapeHandler.bind(modal);
      modal.dismissHandler = dismissHandler.bind(modal);
      modal.captureFocusHandler = captureFocusHandler.bind(modal);
      modal.onHide = null;
      return modal;
    },
  };

  return modal;

})(window, document);
