const extend = require('mode-front-end/resources/assets/js/object/extend');
const throttle = require('mode-front-end/resources/assets/js/event/throttle');
const transitionEvent = require('mode-front-end/resources/assets/js/event/transitionEvent');

module.exports = (function(window, document, undefined) {

  const Headroom = require('headroom.js');

  let header, headroom, addOn;
  let hasPastThreshold = false;

  // If no header, return noop
  if (!document.querySelector('.c-header')) {
    return {
      init: () => {},
      headroom: () => ({ elem: null }),
      addOn: () => ({ el: null })
    };
  }

  // Create custom events for Headroom.js to hook up header-based layout changes
  const stickyNavChangedEvent = document.createEvent('CustomEvent');
  stickyNavChangedEvent.initEvent('stickyNavChanged', true, true);

  const noticeBanner = document.querySelector('.notice-banner')

  /**
   * Transition to full nav
   * @return {void}
   */
  function switchToDefaultNav() {
    if (!hasPastThreshold) {
      return false;
    }

    let nav = this.elem;

    let transitionEnd = function(e) {
      if (noticeBanner) {
        noticeBanner.classList.remove('c-banner--sticky')
        noticeBanner.classList.remove('c-banner--slide-up')
      }

      nav.classList.remove('c-header--sticky');

      nav.classList.remove('c-header--slide-up');
      window.dispatchEvent(stickyNavChangedEvent);
    };

    setTimeout(transitionEnd, 400);
    nav.classList.add('c-header--slide-up');
    if (noticeBanner) {
      noticeBanner.classList.add('c-banner--slide-up')
    }
    document.body.classList.remove('has-sticky-nav');
  }

  /**
   * Transition to essentials nav
   * @return {void}
   */
  function switchToStickyNav() {
    hasPastThreshold = true;

    let nav = this.elem;

    let transitionEnd = function(e) {
      if (noticeBanner) {
        noticeBanner.classList.add('c-banner--sticky');
        noticeBanner.classList.remove('c-banner--slide-up');
      }

      nav.classList.add('c-header--sticky');
      document.body.classList.add('has-sticky-nav');
      nav.classList.remove('c-header--slide-up');
      window.dispatchEvent(stickyNavChangedEvent);
    };

    setTimeout(transitionEnd, 400);
    nav.classList.add('c-header--slide-up');
    if (noticeBanner) {
      noticeBanner.classList.add('c-banner--slide-up');
    }
  }



  // ------------------------------
  // Header Add-ons
  // ------------------------------

  // Create custom events for Headroom.js to hook up header-based layout changes
  const headerAddOnChangedEvent = document.createEvent('CustomEvent');
  headerAddOnChangedEvent.initEvent('headerAddOnChanged', true, true);

  /**
   * Create dropdown based on toggle button's hash.
   * @param  {Element}  button
   */
  function HeaderAddOn(el) {
    if (!el) {
      return false;
    }

    this.el = el;

    this.options = extend({
      scrollTop: null,
      scrollTopOffset: 250
    }, JSON.parse(this.el.getAttribute('data-header-add-on') || '{}'));

    this.scrollTopElement = document.querySelector(this.options.scrollTop);
    this.setScrollTop();

    this.isOpen = false;
  }

  /**
   * Get scrollTop position relative to document.
   */
  HeaderAddOn.prototype.setScrollTop = function() {
    this.scrollTop = this.options.scrollTopOffset;

    if (this.scrollTopElement) {
      this.scrollTop += (this.scrollTopElement.getBoundingClientRect().top - document.body.getBoundingClientRect().top);
    }
  };

  HeaderAddOn.prototype.open = function() {
    let transitionEnd = (function(e) {
      this.isOpen = true;
      window.dispatchEvent(headerAddOnChangedEvent);
      this.el.removeEventListener(transitionEvent('end'), transitionEnd);
    }).bind(this);

    this.el.addEventListener(transitionEvent('end'), transitionEnd);
    this.el.classList.add('is-active');
  };

  HeaderAddOn.prototype.close = function() {
    let transitionEnd = (function(e) {
      this.isOpen = false;
      window.dispatchEvent(headerAddOnChangedEvent);
      this.el.removeEventListener(transitionEvent('end'), transitionEnd);
    }).bind(this);

    this.el.addEventListener(transitionEvent('end'), transitionEnd);
    this.el.classList.remove('is-active');
  };

  /**
   * Open/close header add-on based on document scroll position.
   * @param  {Event}  e
   */
  function addOnScrollHandler(e) {
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    // TODO: Bottom threshold
    if (scrollTop >= addOn.scrollTop) {
      addOn.open();
      header.classList.add('has-add-on');
    } else {
      addOn.close();
      header.classList.remove('has-add-on');
    }
  }



  // ------------------------------
  // Init
  // ------------------------------

  function init() {
    if (typeof headroom !== 'undefined' &&
      typeof headroom.destroy === 'function') {
      headroom.destroy();
    }

    // Headroom
    header = document.querySelector('.c-header');

    let options = JSON.parse(header.getAttribute('data-headroom') || '{}');

    // Set `data-headroom="false"` to disable sticky nav
    if (options === false) {
      return false;
    }

    // Otherwise, init with options
    headroom = new Headroom(header, extend({
      offset: 150,
      tolerance: 3,
      onTop: switchToDefaultNav,
      onNotTop: switchToStickyNav
    }, options));

    headroom.init();

    // Add-on
    addOn = new HeaderAddOn(document.querySelector('.c-header__add-on'));

    if (addOn.el) {
      window.addEventListener('scroll', throttle(addOnScrollHandler, 100));
      window.addEventListener('resize', throttle(addOn.setScrollTop.bind(addOn), 100));
      // Open Add-on if dropdown receives focus
      const dropEle = document.getElementById("health-preference-options");      
      if (dropEle) {
        dropEle.addEventListener('focus', function (e) {
          let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
          const n = addOn.scrollTop + 50; // hack for medium screen size
          if (scrollTop <= n) {
            window.scroll({
              top: n,
              left: 0,
              behavior: 'smooth'
            });
          }
          addOn.open();
        });
      }
    }
  }

  // ------------------------------
  // Public API
  // ------------------------------

  return {
    init: init,
    headroom: () => headroom,
    addOn: () => addOn || { el: null }
  };

})(window, document);
