require('mode-front-end/resources/assets/js/shim/requestAnimationFrame');
require('core-js/fn/array/from');

module.exports = (function(window, document, undefined) {

  const videoSelector = 'video[data-lazyload]';
  const videoSourceAttribute = 'data-lazyload';
  const hasLoadedClass = 'has-loaded';

  /**
   * Add ready class when video loads.
   * @return {void}
   */
  function addReadyClass() {
    this.hasLoaded = true;
    this.classList.add(hasLoadedClass);
  }

  /**
   * Fallback to image.
   * @see https://webkit.org/blog/6784/new-video-policies-for-ios/
   * @return {void}
   */
  function fallback(e) {
    console.log(e)
    let img = this.querySelector('img');

    if (img) {
      this.parentNode.replaceChild(img, this);
    }
  }

  /**
   * Load video, fallback on error.
   * @param  {Element}  video
   * @return {Element}
   */
  function load(video) {
    if (video.hasLoaded) { return; }

    let isVisible = window.getComputedStyle(video).display !== 'none';
    let src = video.getAttribute(videoSourceAttribute);

    video.addEventListener('error', fallback);

    if (isVisible && src) {
      video.src = src;
    }

    requestAnimationFrame(function() {
      video.addEventListener('loadeddata', addReadyClass);

      if (video.hasAttribute('autoplay')) {
        let promise = video.play();
        if (promise !== undefined) {
          promise.catch(fallback.bind(video));
        }
      }
    });

    return video;
  }

  /**
   * Load HTML5 videos.
   * @return {Array}
   */
  function init() {
    return Array.from(document.querySelectorAll(videoSelector)).map(load);
  }

  init();
  window.addEventListener('resize', init);

})(window, document);
