var dom = require('mode-front-end/resources/assets/js/dom');
var map = require('mode-front-end/resources/assets/js/array/map');
var transitionEvent = require('mode-front-end/resources/assets/js/event/transitionEvent');

module.exports = (function(window, document, undefined) {

  // TODO: Move to mode-front-end
  function mapObj(obj, callback) {
    // Make sure callback is a function
    if (typeof callback !== 'function') {
      return null;
    }

    var result = [];

    // Loop through object properties
    for (var prop in obj) {
        if (!obj.hasOwnProperty(prop)) {
          continue;
        }

        // Pass prop to callback; Add to result
        result.push(callback(obj[prop]));
    }

    return result;
  }



  var tabsSelector     = '.js-tabs',
    tabsButtonSelector = '.js-tabs__button',
    tabsPaneSelector   = '.js-tabs__pane',
    activeClass        = 'is-active';

  /**
   * Create tab based on toggle button's hash.
   * @param  {Element}  button
   */
  function Tabs(tabs) {
    // Organize buttons by hash
    this.buttons = {};
    var buttons = tabs.querySelectorAll(tabsButtonSelector);
    for (let i = 0; i < buttons.length; i++) {
      this.buttons[buttons[i].hash] = buttons[i];
    }

    // Organize panes by hash
    this.panes = {};
    var panes = tabs.querySelectorAll(tabsPaneSelector);
    for (let i = 0; i < panes.length; i++) {
      // Note: Add hash to avoid string replace during lookup
      this.panes['#' + panes[i].id] = panes[i];
    }

    // Event listeners
    this.buttonClickListener = this.buttonClick.bind(this);

    this.init();
  }

  // Add click handler to toggle button
  Tabs.prototype.init = function() {
    mapObj(this.buttons, (function(button) {
      button.addEventListener('click', this.buttonClickListener);
    }).bind(this));
  };

  // Open selected pane
  Tabs.prototype.buttonClick = function(e) {
    if (!!e) {
      e.preventDefault();
      e.stopPropagation();
    }

    // Note: Assumes target is button
    var hash = e.target.hash;

    if (!hash || !this.panes[hash]) {
      return false;
    }

    // Reset buttons and panes
    mapObj(this.buttons, this.deactivate);
    mapObj(this.panes, this.deactivate);

    // Activate selected
    this.activate(this.buttons[hash]);
    this.activate(this.panes[hash]);
  };

  Tabs.prototype.activate = function(el) {
    el.classList.add(activeClass);
  };
  Tabs.prototype.deactivate = function(el) {
    el.classList.remove(activeClass);
  };



  return map(document.querySelectorAll(tabsSelector), function(tabs, index) {
    return new Tabs(tabs);
  });

})(window, document);
