// If you want to add an extra 300KB+ to your site's JS, use Video.js
// window.videojs = require('video.js');
// require('videojs-youtube');

var dom = require('mode-front-end/resources/assets/js/dom');
var map = require('mode-front-end/resources/assets/js/array/map');
var transitionEvent = require('mode-front-end/resources/assets/js/event/transitionEvent');
var extend = require('mode-front-end/resources/assets/js/object/extend');

var analytics = require('../analytics');
var iOS = require('mode-front-end/resources/assets/js/browserDetect/ios');
var modals = require('../modal');
var Modal = require('../modal/modal');
var debounce = require('../libs/debounce');

// TODO: Move to mode-front-end
var YT_ready = require('./youtube-ready');

module.exports = (function(window, document, undefined) {

  var openButtonSelector = '.js-play-video',
    containerSelector    = '.js-video',
    readyClass           = 'is-ready',
    activeClass          = 'is-active',
    loadingClass         = 'is-loading',
    playingClass         = 'is-playing',
    bodyActiveClass      = 'has-video-playing',
    bodyBackdropClass    = 'has-video-backdrop';

  var body            = document.querySelector('body'),
    openButtonsByHash = [],
    videoPlayers      = {};

  var trackEvent = analytics.getTrackEvent({
    'eventCategory'       : 'Video Player',
    // 'eventAction'         : '',
    // 'eventLabel'          : '',
    'eventNonInteraction' : false
  });

  var trackEventCustom = analytics.getTrackEvent({
    // 'eventCategory'       : 'Video Player',
    // 'eventAction'         : '',
    // 'eventLabel'          : '',
    'eventNonInteraction' : false
  });

  // HACK: Detect iOS devices to hide controls and handle separate touch events
  var isIOS = iOS.is();

  // ------------------------------
  // Helpers
  // ------------------------------

  /**
   * Get browser-specific fullscreen functions. If unavailable, return null so
   * button and events can be disabled.
   * @return {Object}
   */
  function fullscreenProps() {
    // Standard & Prefixes
    // https://developer.mozilla.org/en-US/docs/Web/API/Fullscreen_API#Prefixing
    // https://github.com/videojs/video.js/blob/0b26d83e9981560f641f70feb270d6ddcc49ec41/src/js/fullscreen-api.js
    if (document.body.requestFullscreen) {
      return {
        request : document.body.requestFullscreen,
        cancel  : document.exitFullscreen,
        event   : 'fullscreenchange'
      };
    }
    // Webkit
    if (document.body.webkitRequestFullscreen) {
      return {
        request : document.body.webkitRequestFullscreen,
        cancel  : document.webkitExitFullscreen,
        event   : 'webkitfullscreenchange'
      };
    }
    // Gecko (Firefox)
    if (document.body.mozRequestFullScreen) {
      return {
        request : document.body.mozRequestFullScreen,
        cancel  : document.mozCancelFullScreen,
        event   : 'mozfullscreenchange'
      };
    }
    // Internet Explorer 11
    // https://msdn.microsoft.com/library/dn265028
    if (document.body.msRequestFullscreen) {
      return {
        request : document.body.msRequestFullscreen,
        cancel  : document.msExitFullscreen,
        event   : 'MSFullscreenChange'
      };
    }

    return null;
  }

  /**
   * Get browser-specific fullscreen element.
   * @return {Element}
   */
  function getFullScreenElement() {
    var fullscreenElement =
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement;

    return fullscreenElement;
  }

  /**
   * Get YouTube ID. Taken from https://gist.github.com/takien/4077195
   * Consider using https://github.com/jmorrell/get-youtube-id/blob/master/index.js
   * @param  {String}  url
   * @return {String}
   */
  function getYouTubeId(url) {
    var id = '';
    url = url.replace(/(>|<)/gi,'').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (url[2] !== undefined) {
      id = url[2].split(/[^0-9a-z_\-]/i);
      id = id[0];
    } else {
      id = url;
    }
    return id;
  }

  // ------------------------------
  // Video Player
  // ------------------------------

  /**
   * Create video player from a video element's hash
   * @param  {String}  hash
   */
  function VideoPlayer(hash) {
    this.figureSelector = hash;
    this.figure = document.querySelector(hash);
    this.returnFocusElement = null;

    if (!this.figure) {
      return null;
    }
    this.container = dom.closest(this.figure, containerSelector);
    this.options = JSON.parse(this.figure.getAttribute('data-video') || '{}');
    this.options = extend({
      hasBackdrop: false,
      playsInline: true,
      modal: ''
    }, this.options);

    this.videoId = getYouTubeId(this.options.src);
    if (!this.videoId) {
      // console.log('Invalid src set for ' + this.figureSelector);
      return null;
    }

    // Assumes you have a placeholder div in your markup
    // Should have the same id as the figure, plus `-iframe`
    this.iframeId = this.figureSelector + '-iframe';
    this.iframe   = this.figure.querySelector(this.iframeId);
    this.iframe.loading = 'lazy';
    this.iframe.setAttribute('aria-hidden', 'true')
    if (!this.iframe) {
      // console.log('No iframe found for ' + this.figureSelector);
      return null;
    }

    // Controls
    // this.controls = {};
    // this.controls.container  = this.figure.querySelector('.js-video__controls');
    // this.controls.playPause  = this.figure.querySelector('.js-video__play-pause');
    // this.controls.volume     = this.figure.querySelector('.js-video__volume');
    // this.controls.fullscreen = this.figure.querySelector('.js-video__fullscreen');
    // this.controls.trackBar   = this.figure.querySelector('.js-video__track-bar');
    // this.controls.track      = this.figure.querySelector('.js-video__track');
    // this.controls.buffer     = this.figure.querySelector('.js-video__buffer');
    // this.controls.scrubber   = this.figure.querySelector('.js-video__scrub');

    // Close button
    this.closeButton = this.figure.querySelector('.js-video__close');

    // Event listeners
    this.openClickListener         = this.openClick.bind(this);
    this.closeClickListener        = this.closeClick.bind(this);
    this.bodyClickListener         = this.bodyClick.bind(this);
    this.figureMouseOverListener   = this.figureMouseOver.bind(this);
    this.figureMouseOutListener    = this.figureMouseOut.bind(this);
    this.playPauseClickListener    = this.playPauseClick.bind(this);

    // this.volumeClickListener       = this.volumeClick.bind(this);
    // this.trackBarClickListener     = this.trackBarClick.bind(this);
    // this.trackBarMouseUpListener   = this.trackBarMouseUp.bind(this);
    // this.trackBarMouseDownListener = this.trackBarMouseDown.bind(this);
    // this.trackBarMouseMoveListener = this.trackBarMouseMove.bind(this);

    // Fullscreen Pt 1: Only add fullscreen button if fullscreen is available
    this.fullscreenProps           = fullscreenProps();
    this.fullscreenChangeListener  = () => {};
    if (this.fullscreenProps) {
      this.fullscreenClickListener  = this.fullscreenClick.bind(this);
      this.fullscreenChangeListener = (e) => {
        if (getFullScreenElement()) {
          this.isFullScreen = true;
          body.classList.add('is-fullscreen');

          // GA: Track video player
          this.fullscreenCount++;
          trackEvent({
            'eventAction' : 'Entered Fullscreen',
            'eventLabel'  : window.location.pathname + ' / ' + this.videoId,
            'eventValue'  : this.fullscreenCount
          });

          return;
        }

        document.removeEventListener(this.fullscreenProps.event, this.fullscreenChangeListener);
        this.isFullScreen = false;
        body.classList.remove('is-fullscreen');

        // GA: Track video player
        this.exitFullscreenCount++;
        trackEvent({
          'eventAction' : 'Exited Fullscreen',
          'eventLabel'  : window.location.pathname + ' / ' + this.videoId,
          'eventValue'  : this.exitFullscreenCount
        });
      };
    // Hide button if fullscreen is unavailable
    } else {
      // this.controls.fullscreen.style.display = 'none';
    }

    // Tracking measures
    this.secondsPlayed             = 0;
    this.marks                     = [10, 20, 30, 40, 50, 60, 70, 80, 90];
    this.marksReached              = [];
    this.marksWatched              = [];
    this.openedCount               = 0;
    this.closedCount               = 0;
    this.closedButtonCount         = 0;
    this.closedBodyCount           = 0;
    this.playedCount               = 0;
    this.resumedCount              = 0;
    this.playedButtonCount         = 0;
    this.pausedCount               = 0;
    this.pausedButtonCount         = 0;
    this.fastForwardCount          = 0;
    this.rewindCount               = 0;
    this.finishedCount             = 0;
    this.mutedCount                = 0;
    this.unmutedCount              = 0;
    this.fullscreenCount           = 0;
    this.fullscreenButtonCount     = 0;
    this.exitFullscreenCount       = 0;
    this.exitFullscreenButtonCount = 0;

    // To initialize...
    this.player           = null;
    this.modal            = null;
    this.isReady          = false;
    this.isOpen           = false;
    this.hasPlayed        = false;
    this.isPlaying        = false;
    this.isWaitingToPlay  = false;
    this.resumePlaying    = false;
    this.isFullScreen     = false;
    this.playInterval     = null;
    this.bufferInterval   = null;
    this.playedPercentage = 0;
    this.totalDuration    = 0;
    this.init();
  }

  VideoPlayer.prototype.setEventHandler = function(eventHandler) {
    this.eventHandler = eventHandler
  }

  /**
   * Initialize YouTube player
   * @return {void}
   */
  VideoPlayer.prototype.init = function() {
    var self = this;
    var hl = 'en';
    var cc_load_policy = 0;
    var cc_lang_pref = 'en';

    // Get the language preference from the html tag
    let lang_pref = window.document.documentElement.getAttribute('lang')
    if (lang_pref != 'en') {
      cc_load_policy = 1
      cc_lang_pref = lang_pref
      hl = lang_pref
    }

    YT_ready((() => {
      /**
       * YouTube IFrame Player
       * - API https://developers.google.com/youtube/iframe_api_reference
       * - Demo https://developers.google.com/youtube/youtube_player_demo
       * - Parameters https://developers.google.com/youtube/player_parameters
       */
      self.player = new YT.Player(self.iframe, {
        videoId: self.videoId,
        playerVars: {
          autohide: 1,
          autoplay: 0,
          controls: 1,
          enablejsapi: 1,
          hl: hl,
          cc_load_policy: cc_load_policy,
          cc_lang_pref: cc_lang_pref,
          fs: 1, // Disable default fullscreen button
          iv_load_policy: 3, // Disable annotations
          modestbranding: 1, // Set to 0 to always show YouTube logo (otherwise, shows up behind fullscreen button on hover)
          playsinline: self.playsInline,
          height: 200,
          width: 600,
          // You should always specify your domain as the origin parameter value.
          // origin: 'http://localhost:3000/',
          origin: `${window.location.protocol}//${window.location.hostname}`,
          rel: 0,
          showinfo: 0,
          wmode: 'transparent' // Fix fullscreen z-index
        },
        events: {
          onReady: (e) => {
            this.setQuality(e);
            // Update the iframe reference when the player is ready. otherwise it represents
            // the original div that gets replaced
            this.iframe = this.figure.querySelector(self.iframeId);

            // Add hover state to player buttons
            this.figure.addEventListener('mouseover', this.figureMouseOverListener, false);
            this.figure.addEventListener('mouseout', this.figureMouseOutListener, false);
            this.isReady = true;
            this.hasPlayedOnce = false;
            this.container.classList.add(readyClass);
            this.container.classList.add(loadingClass);
            if (this.isWaitingToPlay) {
              this.playVideo();
            }
          },
          onStateChange: (e) => {
            // Open player once video is playing. Allows custom poster image and CTA without forcing touch devices to click twice to play.
            if (this.isOpen === false && (e.data === YT.PlayerState.PLAYING || (e.data === YT.PlayerState.BUFFERING && !this.hasPlayedOnce))) {
              this.open();
            }
            if (e.data === YT.PlayerState.ENDED) {
              if (typeof this.eventHandler !== 'undefined' && typeof this.eventHandler['onStateChange'] === 'function') {
                this.eventHandler['onStateChange']('ended')
              }
              // GA: Track video player
              this.finishedCount++;
              trackEvent({
                'eventAction' : 'Finished Video ' + this.finishedCount + 'x',
                'eventLabel'  : window.location.pathname + ' / ' + this.videoId,
                'eventValue'  : Math.round(this.secondsPlayed / this.totalDuration * 100)
              });

              // Reset tracking measures
              this.hasPlayedOnce = true;
              this.hasPlayed     = false;
              this.secondsPlayed = 0;
              this.marksReached  = [];
              this.marksWatched  = [];

              clearInterval(this.playInterval);
              this.isPlaying = false;
              // this.controls.container.classList.remove(playingClass);
              this.player.pauseVideo();
              this.player.seekTo(0);
              this.close();
              if (this.modal){
                this.modal.hide();
              }
              return;
            }

            if (e.data === YT.PlayerState.PLAYING) {
              // Fade in video from black
              this.container.classList.remove(loadingClass);

              // Update duration
              this.totalDuration = this.player.getDuration();

              // Update buffer
              // if (!this.isPlaying && !this.bufferInterval) {
              //   this.bufferInterval = setInterval(() => this.updateBuffer(), 100);
              // }

              this.isPlaying = true;
              // this.controls.container.classList.add(playingClass);
              // TODO: Possible to merge this with `startTimer`? Only diff is interval, 500ms vs 1000ms
              // this.playInterval = setInterval(() => this.updatePlayerTrack(), 500);
              this.startTimer();

              // GA: Track video player
              if (!this.hasPlayed) {
                this.hasPlayed = true;
                this.playedCount++;
                trackEvent({
                  'eventAction' : 'Played Video',
                  'eventLabel'  : window.location.pathname + ' / ' + this.videoId,
                  'eventValue'  : this.playedCount
                });
              } else {
                this.resumedCount++;
                trackEvent({
                  'eventAction' : 'Resumed Video',
                  'eventLabel'  : window.location.pathname + ' / ' + this.videoId,
                  'eventValue'  : this.resumedCount
                });
              }

              if (typeof this.eventHandler !== 'undefined' && typeof this.eventHandler['onStateChange'] === 'function') {
                this.eventHandler['onStateChange']('playing')
              }

              return;
            }

            if (e.data === YT.PlayerState.PAUSED) {
              // clearInterval(this.playInterval);
              this.isPlaying = false;
              // this.controls.container.classList.remove(playingClass);
              this.stopTimer();

              // GA: Track video player
              this.pausedCount++;
              trackEvent({
                'eventAction' : 'Paused Video',
                'eventLabel'  : window.location.pathname + ' / ' + this.videoId,
                'eventValue'  : this.pausedCount
              });
              trackEvent({
                'eventAction' : 'Paused Video at %',
                'eventLabel'  : Math.round(this.playedPercentage),
                'eventValue'  : Math.round(this.playedPercentage)
              });

              if (typeof this.eventHandler !== 'undefined' && typeof this.eventHandler['onStateChange'] === 'function') {
                this.eventHandler['onStateChange']('paused')
              }

              // It as determined that the video should not close out on pause.
              // https://3.basecamp.com/3438130/buckets/1643225/todos/1216817896#__recording_1258548495
              //this.close();
              return;
            }

            if (e.data === YT.PlayerState.BUFFERING) {
              this.setQuality(e);
              this.isPlaying = false;
              if (typeof this.eventHandler !== 'undefined' && typeof this.eventHandler['onStateChange'] === 'function') {
                this.eventHandler['onStateChange']('buffering')
              }
              return;
            }

            if (e.data === YT.PlayerState.CUED) {
              // clearInterval(this.playInterval);
              this.isPlaying = false;
              if (typeof this.eventHandler !== 'undefined' && typeof this.eventHandler['onStateChange'] === 'function') {
                this.eventHandler['onStateChange']('cued')
              }
              return;
            }

          }
        }
      });
    }));

    // Add event handlers for existing controls
      if (this.closeButton) {
        this.closeButton.addEventListener('click', this.closeClickListener);
      }
    //   if (this.controls.playPause) {
    //     this.controls.playPause.addEventListener('click', this.playPauseClickListener);
    //   }
    //   if ( this.controls.volume) {
    //     this.controls.volume.addEventListener('click', this.volumeClickListener);
    //   }
    //   if (this.controls.trackBar) {
    //     this.controls.trackBar.addEventListener('click', this.trackBarClickListener);
    //     this.controls.trackBar.addEventListener('mousedown', this.trackBarMouseDownListener);
    //   }
    //   // Fullscreen Pt 2: Only add fullscreen button if fullscreen is available
    //   if (this.fullscreenProps && this.controls.fullscreen) {
    //      this.controls.fullscreen.addEventListener('click', this.fullscreenClickListener);
    //   }


    // Modal
      if (this.options.modal && modals[this.options.modal]) {
        this.modal = modals[this.options.modal];
        if (this.closeButton) {
          this.closeButton.style.display = 'none';
        }
    }

    };


  //
  // VideoPlayer.prototype.getSeekPercentage = function(e) {
  //   var trackBarRect = this.controls.trackBar.getBoundingClientRect();
  //   return (e.pageX - trackBarRect.left) / trackBarRect.width;
  // };
  //
  // VideoPlayer.prototype.getSeekPosition = function(e) {
  //   return this.totalDuration * this.getSeekPercentage(e);
  // };
  //
  // VideoPlayer.prototype.updatePlayerTrack = function() {
  //   this.playedPercentage  = (this.player.getCurrentTime() / this.totalDuration) * 100;
  //   var percentagePosition = this.playedPercentage - 100;
  //   this.controls.scrubber.style.transform = 'translateX(' + percentagePosition + '%)';
  // };
  //
  // VideoPlayer.prototype.updateBuffer = function(player) {
  //   if (this.player.getVideoLoadedFraction() === 1) {
  //     clearInterval(this.bufferInterval);
  //     this.bufferInterval = null;
  //   }
  //
  //   var bufferPercentage = (this.player.getVideoLoadedFraction() * 100) - 100;
  //   this.controls.buffer.style.transform = 'translateX(' + bufferPercentage + '%)';
  // };

  /**
   * Open video player
   * @param  {Event}  e
   * @return {void}
   */
  VideoPlayer.prototype.openClick = function(e) {

    this.returnFocusElement = e.currentTarget;

    console.log('returnFocus Element is:');
    console.log(this.returnFocusElement);
    console.log('openClick fired - blurring returnFocusElement');
    this.returnFocusElement.blur();

    if (!!e) {
      e.preventDefault();
      e.stopPropagation();
    }

    // Ignore open click if the it's already open
    if (this.container.classList.contains(activeClass)) {
      return;
    }

    this.open();

    // GA: Track video player
    this.openedButtonCount++;
    trackEvent({
      'eventAction' : 'Clicked Open Button',
      'eventLabel'  : window.location.pathname + ' / ' + this.videoId,
      'eventValue'  : this.openedButtonCount
    });
  };

  /**
   * Close video player
   * @param  {Event}  e
   * @return {void}
   */
  VideoPlayer.prototype.closeClick = function(e) {
    if (!!e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (this.isFullScreen) {
      this.fullscreenProps.cancel.call(document);
    }

    this.close();

    // GA: Track video player
    this.closedButtonCount++;
    trackEvent({
      'eventAction' : 'Clicked Close Button',
      'eventLabel'  : window.location.pathname + ' / ' + this.videoId,
      'eventValue'  : this.closedButtonCount
    });
  };

  /**
   * Close video on body clicks
   * @param  {Event}  e
   * @return {void}
   */
  VideoPlayer.prototype.bodyClick = function(e) {
    if (!!e) {
      e.preventDefault();

      // Don't close on clicks inside of video
      if (dom.closest(e.target, this.figureSelector)) {
        return false;
      }
    }

    this.close();

    // GA: Track video player
    this.closedBodyCount++;
    trackEvent({
      'eventAction' : 'Clicked Close Button (Body)',
      'eventLabel'  : window.location.pathname + ' / ' + this.videoId,
      'eventValue'  : this.closedBodyCount
    });
  };

  /**
   * Add hover state to open buttons
   * @param  {Event}  e
   * @return {void}
   */
  VideoPlayer.prototype.figureMouseOver = function(e) {
    if (!!e) {
      e.preventDefault();
      e.stopPropagation();
    }
    // Button active state
    map(openButtonsByHash[this.figureSelector], function(button) {
      button.classList.add(activeClass);
    });
    // Show controls
    // this.controls.container.classList.add('is-active');
    this.closeButton.classList.add('is-active');
  };
  VideoPlayer.prototype.figureMouseOut = function(e) {
    if (!!e) {
      e.preventDefault();
      e.stopPropagation();
    }
    // Button active state
    map(openButtonsByHash[this.figureSelector], function(button) {
      button.classList.remove(activeClass);
    });
    // Hide controls
    // this.controls.container.classList.remove('is-active');
    this.closeButton.classList.remove('is-active');
  };

  // toggle the play/pause state of the player
  VideoPlayer.prototype.playPauseClick = function(e) {

    e.preventDefault();

    if (!this.isPlaying) {
      this.playVideo();

      // GA: Track video player
      this.playedButtonCount++;
      trackEvent({
        'eventAction' : 'Clicked Play Button',
        'eventLabel'  : window.location.pathname + ' / ' + this.videoId,
        'eventValue'  : this.playedButtonCount
      });

    } else {
      this.player.pauseVideo();

      // GA: Track video player
      this.pausedButtonCount++;
      trackEvent({
        'eventAction' : 'Clicked Pause Button',
        'eventLabel'  : window.location.pathname + ' / ' + this.videoId,
        'eventValue'  : this.pausedButtonCount
      });
    }
  };

  // VideoPlayer.prototype.trackBarMouseDown = function(e) {
  //   e.preventDefault();
  //
  //   // enable scrubbing
  //   document.body.addEventListener('mousemove', this.trackBarMouseMoveListener);
  //   document.body.addEventListener('mouseup', this.trackBarMouseUpListener);
  // };
  //
  // VideoPlayer.prototype.trackBarMouseUp = function(e) {
  //   e.preventDefault();
  //
  //   document.body.removeEventListener('mousemove', this.trackBarMouseMoveListener);
  //   document.body.removeEventListener('mouseup', this.trackBarMouseUpListener);
  //
  //   this.player.seekTo(this.getSeekPosition(e), true);
  //
  //   if (this.resumePlaying) {
  //     this.playVideo();
  //     this.resumePlaying = false;
  //   }
  // };

  // VideoPlayer.prototype.trackBarMouseMove = function(e) {
  //
  //   //console.log(e.pageX);
  //
  //
  //   e.preventDefault();
  //   if (this.isPlaying) {
  //     this.player.pauseVideo();
  //     this.resumePlaying = true;
  //   }
  //   // force scrubbing to stay within bounds of the track
  //   var trackBarRect = this.controls.trackBar.getBoundingClientRect();
  //   if (trackBarRect.left > e.pageX || e.pageX > trackBarRect.left + trackBarRect.width) {
  //     return;
  //   }
  //   var scrubPercentage = (this.getSeekPercentage(e) * 100) - 100;
  //   this.controls.scrubber.style.transform = 'translateX(' + scrubPercentage + '%)';
  // };
  //
  // VideoPlayer.prototype.trackBarClick = function(e) {
  //   // since a click was triggered, cleanup the scrubbing handlers
  //   document.body.removeEventListener('mousemove', this.trackBarMouseMoveListener);
  //   document.body.removeEventListener('mouseup', this.trackBarMouseUpListener);
  //
  //   // the mousemove handler is used here to update the scrub position faster after clicking
  //   this.trackBarMouseMove(e);
  //
  //   this.player.seekTo(this.getSeekPosition(e), true);
  //
  //   if (this.resumePlaying) {
  //     this.playVideo();
  //   }
  //
  //   var currentPercentage = this.playedPercentage,
  //     newPercentage = this.getSeekPercentage(e) * 100,
  //     percentageDiff = Math.round(newPercentage - currentPercentage);
  //
  //   // GA: Track video player
  //   if (percentageDiff < 0) {
  //     this.rewindCount++;
  //     trackEvent({
  //       'eventAction' : 'Rewound Video',
  //       'eventLabel'  : window.location.pathname + ' / ' + this.videoId,
  //       'eventValue'  : Math.abs(percentageDiff)
  //     });
  //   } else {
  //     this.fastForwardCount++;
  //     trackEvent({
  //       'eventAction' : 'Fast Forwarded Video',
  //       'eventLabel'  : window.location.pathname + ' / ' + this.videoId,
  //       'eventValue'  : Math.abs(percentageDiff)
  //     });
  //   }
  // };

  // VideoPlayer.prototype.volumeClick = function(e) {
  //   e.preventDefault();
  //   if (this.player.isMuted()) {
  //     // this.controls.container.classList.remove('is-muted');
  //     this.player.unMute();
  //
  //     // GA: Track video player
  //     this.unmutedCount++;
  //     trackEvent({
  //       'eventAction' : 'Clicked Unmute',
  //       'eventLabel'  : window.location.pathname + ' / ' + this.videoId,
  //       'eventValue'  : this.unmutedCount
  //     });
  //     return;
  //   }
  //
  //   // this.controls.container.classList.add('is-muted');
  //   this.player.mute();
  //
  //   // GA: Track video player
  //   this.mutedCount++;
  //   trackEvent({
  //     'eventAction' : 'Clicked Mute',
  //     'eventLabel'  : window.location.pathname + ' / ' + this.videoId,
  //     'eventValue'  : this.mutedCount
  //   });
  // };

  VideoPlayer.prototype.fullscreenClick = function(e) {
    e.preventDefault();

    // Fullscreen Pt 3: Only add fullscreen button if fullscreen is available
    if (!this.fullscreenProps) {
      return false;
    }

    if (this.isFullScreen) {
      this.fullscreenProps.cancel.call(document);

      // GA: Track video player
      this.exitFullscreenButtonCount++;
      trackEvent({
        'eventAction' : 'Clicked Exit Fullscreen',
        'eventLabel'  : window.location.pathname + ' / ' + this.videoId,
        'eventValue'  : this.exitFullscreenButtonCount
      });

      return;
    }

    if (this.fullscreenProps.event) {
      document.addEventListener(this.fullscreenProps.event, this.fullscreenChangeListener);
    }

    this.fullscreenProps.request.call(this.figure);

    // GA: Track video player
    this.fullscreenButtonCount++;
    trackEvent({
      'eventAction' : 'Clicked Enter Fullscreen',
      'eventLabel'  : window.location.pathname + ' / ' + this.videoId,
      'eventValue'  : this.fullscreenButtonCount
    });
  };

  /**
   * Force high quality playback on larger devices
   * http://stackoverflow.com/a/10757854/1786459
   * @param  {Event}    e
   * @return {Boolean}
   */
  VideoPlayer.prototype.setQuality = function(e) {
    if (!e || typeof e.target.setPlaybackQuality !== 'function') {
      return false;
    }

    // Force high quality videos on devices >= iPad size (assuming larger screens have better performance)
    if (dom.window.width() >= 768) {
      e.target.setPlaybackQuality('hd720');
    }

    return true;
  };

  /**
   * Track percentage completed
   * @return {void}
   */
  VideoPlayer.prototype.trackPercentageCompleted = function() {
    var watchedPercentage = Math.round(this.secondsPlayed / this.totalDuration * 100);

    // GA: Track video player
    map(this.marks, (function(mark, index) {
      // % Reached
      if (this.marksReached.indexOf(mark) < 0 && this.playedPercentage >= mark) {
        this.marksReached.push(mark);

        trackEvent({
          'eventAction' : 'Reached ' + mark + '%',
          'eventLabel'  : window.location.pathname + ' / ' + this.videoId,
          'eventValue'  : watchedPercentage
        });
      }
      // % Actually Watched
      if (this.marksWatched.indexOf(mark) < 0 && watchedPercentage >= mark) {
        this.marksWatched.push(mark);

        trackEvent({
          'eventAction' : 'Watched ' + mark + '%',
          'eventLabel'  : window.location.pathname + ' / ' + this.videoId,
          'eventValue'  : this.secondsPlayed
        });
      }
    }).bind(this));
  };

  /**
   * Time seconds user actually viewed of video.
   * @return {void}
   */
  VideoPlayer.prototype.startTimer = function() {
    this.timer = setInterval((function() {
      this.secondsPlayed++;
      this.trackPercentageCompleted();
    }).bind(this), 1000);
  };
  VideoPlayer.prototype.stopTimer = function() {
    clearInterval(this.timer);
  };

  /**
   * Play video if ready. Otherwise, mark as waiting to play.
   * @return {Boolean}
   */
  VideoPlayer.prototype.playVideo = function() {
    if (!this.isReady) {
      this.isWaitingToPlay = true;
      // GA: Track video player
      trackEvent({
        'eventAction' : 'Clicked Play Before Video Was Ready',
        'eventLabel'  : window.location.pathname + ' / ' + this.videoId,
        'eventValue'  : 0
      });
      return false;
    }
    this.player.playVideo();
    this.isWaitingToPlay = false;
    return true;
  };


  /**
   * Handles Escape key when video is not playing in a modal
   * @return {void}
   */
   VideoPlayer.prototype.handleKeyEscape = function(e) {
    if (e.key === 'Escape') {
      this.close();
    }
  };

  // /**
  //  * Handles advancing and retreating the scrubber
  //  *     with the left & right arrow keys
  //  * @return {void}
  //  */
  // VideoPlayer.prototype.handleKeyDown = function(e) {
  //   if (document.activeElement == this.controls.scrubber
  //       && ['ArrowLeft','ArrowRight'].indexOf(e.key) !== -1 ) {
  //     e.preventDefault();
  //     const step = 20;
  //     const tbox = this.controls.trackBar.getBoundingClientRect();
  //     const bbox = this.controls.scrubber.getBoundingClientRect();
  //     e.pageX = (bbox.width + bbox.left);
  //     if (e.key == "ArrowRight") {
  //       e.pageX += step;
  //       if (e.pageX > tbox.right) { e.pageX = tbox.right; }
  //     } else {
  //       e.pageX -= step;
  //       if (e.pageX < tbox.left) { e.pageX = tbox.left; }
  //     }
  //     this.trackBarClick(e);
  //   }
  // };

  /**
   * Handles Tab key when video is not playing in a modal,
   *    otherwise the modal handles keyboard events.
   * @return {void}
   */
  //  VideoPlayer.prototype.handleKeyUp = function(e) {
  //   function isChildOf(child, parent) {
  //     var node = child.parentNode;
  //     while (node != null) {
  //         if (node == parent) {
  //             return true;
  //         }
  //         node = node.parentNode;
  //     }
  //     return false;
  //   }
  //
  //   if (e.key == 'Tab') {
  //     const activeE = document.activeElement;
  //     // reveal mouse over video controls
  //     // this.controls.container.classList.add('is-active');
  //     this.closeButton.classList.add('is-active');
  //
  //     // confine active element tabbing to items within the video container
  //     const feles = Array.from(this.container.querySelectorAll("input, select, button")); // a:not(.js-play-video),
  //     if (feles) {
  //       const firstEle = feles[0];
  //       const lastEle = feles[(feles.length-1)];
  //       if (isChildOf(activeE, this.container)) {
  //         // on first element but tabbing backward, go to last element
  //         if (activeE == firstEle && e.shiftKey) {
  //           e.preventDefault();
  //           lastEle.focus();
  //         }
  //         // on last element but tabbing forward, go to first element
  //         else if (activeE == lastEle && !e.shiftKey) {
  //           e.preventDefault();
  //           firstEle.focus();
  //         }
  //       } else if (e.shiftKey) {
  //         e.preventDefault();
  //         lastEle.focus();
  //       } else {
  //         e.preventDefault();
  //         firstEle.focus();
  //       }
  //     }
  //   }
  // };

  /**
   * Open video player and begin playing video.
   * @return {void}
   */
  VideoPlayer.prototype.open = function() {

    // Force pause other videos
    Object.keys(window.videoPlayers).forEach((otherVideoKey) => {
      if (otherVideoKey != this.figureSelector) {
        window.videoPlayers[otherVideoKey].close()
      }
    })

    this.container.classList.add(activeClass);
    body.classList.add(bodyActiveClass);
    if (this.options.hasBackdrop) {
      body.classList.add(bodyBackdropClass);
    }
    if (this.options.modal) {
      this.modal.onHide = this.close.bind(this);
      this.modal.setReturnFocus(this.returnFocusElement);
      this.modal.show();
    } else {
      this.handleKeyEscape = this.handleKeyEscape.bind(this);
      body.addEventListener('keydown', this.handleKeyEscape);
    }

    // this.handleKeyDown = this.handleKeyDown.bind(this);
    // this.handleKeyUp = this.handleKeyUp.bind(this);
    // body.addEventListener('keydown', this.handleKeyDown);
    // body.addEventListener('keyup', this.handleKeyUp);

    this.playVideo();
    this.setFocus();
    this.iframe.setAttribute('aria-hidden', 'false')
    this.isOpen = true;

    // HACK: Add touchstart event for iOS devices
    if (isIOS) {
      body.addEventListener('touchstart', this.bodyClickListener);
    }
    // GA: Track video player
    this.openedCount++;
    trackEvent({
      'eventAction' : 'Opened Video Player',
      'eventLabel'  : window.location.pathname + ' / ' + this.videoId,
      'eventValue'  : this.openedCount
    });
    // If the video container has an extra click event, (for example, in campaign pages)...
    // track the other custom event too.
    const clickEvent = this.container.getAttribute('data-ga-click-event');
    if (clickEvent && clickEvent.length) {
      var event = JSON.parse(this.container.getAttribute('data-ga-click-event'));
      trackEventCustom(event);
    }
  };

  /**
   * Pause video and close video player.
   * @return {void}
   */
  VideoPlayer.prototype.close = function() {
    body.removeEventListener('keydown', this.handleKeyDown);
    this.container.classList.remove(activeClass);
    this.setFocus();
    if (!this.options.modal) {
      if (this.returnFocusElement && typeof this.returnFocusElement.focus == "function") {
        this.returnFocusElement.focus();
      }
    }
    body.classList.remove(bodyActiveClass);
    if (this.options.hasBackdrop) {
      body.classList.remove(bodyBackdropClass);
    }
    // NOTE: Modals should _not_ automatically close when a video ends.
    // It's an abrupt and unexpected action. Instead, the user should
    // be allowed to control the modal on their own terms.

    if (this.isReady) {
      this.player.pauseVideo();
    }

    // Fullscreen Pt 4: Exit fullscreen on close
    if (this.isFullScreen) {
      this.fullscreenProps.cancel.call(document);
    }

    this.isOpen = false;
    body.removeEventListener('click', this.bodyClickListener);

    // HACK: Add touchstart event for iOS devices
    if (isIOS) {
      body.removeEventListener('touchstart', this.bodyClickListener);
    }

    // GA: Track video player
    this.closedCount++;
    trackEvent({
      'eventAction' : 'Closed Video Player',
      'eventLabel'  : window.location.pathname + ' / ' + this.videoId,
      'eventValue'  : this.closedCount
    });
  };

  VideoPlayer.prototype.setFocus = function () {
    if (!this.closeButton) {
      console.log('close button cannot be found');
      return;
    }
    if (this.container.classList.contains('is-active')) {
      //needs time to focus
      setTimeout(() => {
        this.closeButton.focus();
        this.closeButton.setAttribute("data-focus","focus");
      }, 500);
    } else {
      console.log('container not active, blurring close button');
      this.closeButton.blur();
      this.closeButton.removeAttribute("data-focus");
    }
  }

  // ------------------------------
  // Init
  // ------------------------------

  // Create array of unique video players based on play button hashes
  var _hash;
  var _modalIndex = 0;
  var _modalTemplate = document.querySelector('#video-modal-template');
  if (_modalTemplate) {
    _modalTemplate = _modalTemplate.textContent.replace(/\\\//g, '/').trim();
  } else {
    console.log('could not find #video-modal-template')
  }

  return {
    init: function() {

      console.log('init called')

      openButtonsByHash = [];
      videoPlayers      = {};
      let openButtons = document.querySelectorAll(openButtonSelector);

      for (var i = 0; i < openButtons.length; i++) {
        _hash = openButtons[i].hash;
        /**
         * Video Player Modals
         *
         * If this button points to a video URL instead of a player ID,
         * then create on-the-fly video modals with the following markup:
         *
         * ```html
         * <a href="https://youtu.be/gExT8CZcnx8" class="u-small-caps  /  c-link--cta  /  js-play-video">Play Video</a>
         * ```
         *
         * You can optionally add GA event tracking:
         *
         * ```html
         * <a href="https://youtu.be/gExT8CZcnx8" class="u-small-caps  /  c-link--cta  /  js-play-video" data-ga-click-event='{ "eventCategory": "Home", "eventAction": "Clicked Featured Product (Play Video)", "eventLabel": "https://youtu.be/gExT8CZcnx8" }'>Play Video</a>
         * ```
         *
         * @see resources/patternlab/source/_patterns/02-organisms/02-modals/video-modal.mustache
         */
        if (!_hash && openButtons[i].href) {
          var newVideoModal = document.createElement('div');
          newVideoModal.innerHTML = _modalTemplate;
          newVideoModal = newVideoModal.firstElementChild;

          let h1 = newVideoModal.querySelector("h1");
          if (h1 && openButtons[i].dataset.modalh1) {
            h1.innerHTML = openButtons[i].dataset.modalh1;
          } else if (h1) {
            h1.remove();
          }

          var newVideoModalIndex = ++_modalIndex;
          newVideoModal.id = 'video-modal-' + newVideoModalIndex;

          var newVideoModalFigure = newVideoModal.querySelector('.o-video__figure');
          newVideoModalFigure.id = 'video-modal-' + newVideoModalIndex + '-figure';

          var newVideoModalData = JSON.parse(newVideoModalFigure.getAttribute('data-video'));
          newVideoModalData.src = openButtons[i].href;
          newVideoModalData.modal = '#' + newVideoModal.id;

          newVideoModalFigure.setAttribute('data-video', JSON.stringify(newVideoModalData));
          newVideoModalFigure.querySelector('[id$="iframe"]').id = newVideoModalFigure.id + '-iframe';
          _hash = '#' + newVideoModalFigure.id;

          document.body.appendChild(newVideoModal);
          modals['#' + newVideoModal.id] = Modal.create(newVideoModal);
          openButtons[i].href = '#' + newVideoModal.id;
        }

        // If hash is null or element doesn't exist, continue
        if (!_hash || !document.querySelector(_hash)) {
          continue;
        }

        // If player doesn't already exist, create a new one
        if (!videoPlayers.hasOwnProperty(_hash)) {
          videoPlayers[_hash] = new VideoPlayer(_hash);
        }

        // Group buttons by hash
        openButtonsByHash[_hash] = openButtonsByHash[_hash] || []; // Create array if none exists
        openButtonsByHash[_hash].push(openButtons[i]);

        // Add open event to all buttons for videos in array
        openButtons[i].addEventListener('click', videoPlayers[_hash].openClickListener);
        openButtons[i].addEventListener('touchstart', videoPlayers[_hash].openClickListener);
      }

      window.videoPlayers = videoPlayers
      return videoPlayers;
    }
  }
})(window, document);
